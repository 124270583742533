:root {
  --main-font-family: 'Montserrat', sans-serif;

  --main-color: #757575;
  --primary-color: #212121;
  --secondary-color: #8F8E8D;

  --black-color: #000;
  --white-color: #fff;
  --on-bg-color: #C3C3C3;
  --accent-color: #00bbc6;

  --pagination-color: #C0C4CA;

  --main-bg: #FBF9F7;
  --primary-bg: #353433;
  --secondary-bg: #212121;
  --tertiary-bg: #EDEDED;
  --accent-bg: #ffffff;
  --slider-bg: #F6F2EE;

  --main-border-color: #C7C7C7;
  --secondary-border-color: #C0C4CA;
  --tertiary-border-color: #444342;
  --glossary-border-color: #DEE2E8;
  --accent-border-color: #00bbc6;

  --form-label-color: #757575;
  --form-placeholder-color: #757575;
  --form-error-color: #f00;

  --button-bg: #00bbc6;
  --button-bg-hover: #353433;
  --button2-bg: #353433;
  --button2-bg-hover: #636363;
  --button3-bg: #FFFFFF;
  --button3-bg-hover: #00bbc6;
}

