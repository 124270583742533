
/* font-face */

// @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=DM+Sans:wght@400;500;600;700&family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?89z6j7');
  src: url('../fonts/icomoon.eot?89z6j7#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?89z6j7') format('truetype'),
  url('../fonts/icomoon.woff?89z6j7') format('woff'),
  url('../fonts/icomoon.svg?89z6j7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"] > span,
[class*=" icon-"] > span{display: none;}

.icon-brand-fb:before {
  content: "\61";
}

.icon-brand-tw:before {
  content: "\62";
}

.icon-brand-in:before {
  content: "\63";
}

.icon-angle-l:before {
  content: "\e900";
}

.icon-angle-r:before {
  content: "\e901";
}

.icon-angle-t:before {
  content: "\e902";
}

.icon-angle-b:before {
  content: "\e903";
}

.icon-angle-dbl-l:before {
  content: "\e904";
}

.icon-angle-dbl-r:before {
  content: "\e905";
}

.icon-arrow-l:before {
  content: "\e906";
}

.icon-arrow-r:before {
  content: "\e907";
}

.icon-first:before {
  content: "\e908";
}

.icon-last:before {
  content: "\e909";
}

.icon-plus:before {
  content: "\e90a";
}

.icon-minus:before {
  content: "\e90b";
}

.icon-tick:before {
  content: "\e90c";
}

.icon-cross:before {
  content: "\e90d";
}

.icon-quote-l:before {
  content: "\e90e";
}

.icon-quote-r:before {
  content: "\e90f";
}

.icon-menu-burger:before {
  content: "\e910";
}

.icon-profile:before {
  content: "\e911";
}

.icon-desktop:before {
  content: "\e912";
}

.icon-mobile:before {
  content: "\e913";
}

.icon-download:before {
  content: "\e914";
}

.icon-shield:before {
  content: "\e915";
}

.icon-wallet:before {
  content: "\e916";
}

.icon-conditions:before {
  content: "\e917";
}

.icon-accept:before {
  content: "\e918";
}

.icon-chart-forex:before {
  content: "\e919";
}

.icon-chart-trading:before {
  content: "\e91a";
}

.icon-chart-indicies:before {
  content: "\e91b";
}

.icon-chart-email:before {
  content: "\e91c";
}

.icon-location:before {
  content: "\e91d";
}

.icon-close:before {
  content: "\e91e";
}

.icon-acc-table:before {
  content: "\e91f";
}

.icon-hp-features01:before {
  content: "\e920";
}

.icon-hp-features02:before {
  content: "\e921";
}

.icon-hp-features03:before {
  content: "\e922";
}

.icon-hp-features04:before {
  content: "\e923";
}

.icon-email:before {
  content: "\e924";
}
.icon-phone:before {
  content: "\e925";
}
