$site-domain: "";
@import "variables";
@import "ui";
@import "fonts";
@import "slick";


.spinner-h {
  position: fixed;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  transition: all 0.5s;
}


.error__page {
  background: #0F1821;
  color: #fff;
  h1 {
    font-size: 38px;
  }
  h1, h3 {
    color: #fff;
  }
  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.8rem;
  }
  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }
}

.error__page-content,
.notfound__page-content {
  font-weight: 700;

  h1,
  h3 {
    margin-bottom: 3.1rem;
  }

  > svg,
  > img {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 767px) {

  .error__page-content,
  .notfound__page-content {

    h1 {
      line-height: normal;
      margin-bottom: 2rem;
    }

    h3 {
      margin-bottom: 2rem;
    }

    > svg,
    > img {
      margin-bottom: 2rem;
    }
  }

  .error__page-content {
    padding-bottom: 0;
    padding-top: 6rem;
  }
}


.contactus {
  &__page .main__container {
    background: #1e2831 url("#{$site-domain}/Media/Image/bg-page-contactus.jpg") no-repeat 50% 0;
    background-size: cover;

    h1, .h1 {
      color: #fff;
      letter-spacing: 2px;
      margin-bottom: 5rem;
    }
  }


  &-details {
    .items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0 2rem;
    }

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      background: #0F1821;
      padding: 30px;
      border: 0;
      color: #DBDBDB;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 0;

      h2, .h2 {
        margin-bottom: 0;
        color: #00BBC6;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin-top: 5px;
      }

      em {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: top;
        width: 3rem;
        line-height: 3rem;
        color: var(--accent-color);

        &.icon-bg1 {
          font-size: 20px;
        }

        &.icon-bg2 {
          font-size: 30px;
        }

        &.icon-bg3 {
          font-size: 25px;
        }
      }

      .text {
        flex: 1;

        & > *:last-child {
          margin-bottom: 0;
        }
      }

      p {
        margin-bottom: 1rem;
      }

      a {
        color: #DBDBDB;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-decoration: none
      }
    }
  }


  @media only screen and (max-width: 991px) {
    &__page .main__container {
      h1, .h1 {
        font-size: 2.8rem;
        letter-spacing: 0;
        margin-bottom: 3rem;
      }
    }
    &-details {
      padding: 0;
      max-width: unset;

      .items {
        gap: 2rem;
        flex-direction: column;
        align-items: center;
      }

      .item {
        flex-direction: column;
        gap: 0;
        width: 100%;
        max-width: 500px;

        .text {
          text-align: center;
          max-width: 26.2rem;
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    &-details {
      .items {
        gap: 1rem;
      }

      .item {
        padding: 20px;

        h2 {
          margin-top: 22px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

.hidden-area {
  display: block;
  height: 0;
  overflow: hidden;
  text-indent: -999.9rem;
  width: 0;
}


.section-payment {
  position: relative;
  padding: 2rem 0 !important;
  background: var(--primary-bg);

  .payment {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6.3rem;

    .items {
      display: flex;
      align-items: center;
      gap: 6.3rem;
    }

    .item {
      flex: 1;

      img {
        width: auto;
        max-width: unset;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .content {
    .text {
      text-align: center;
    }
  }

  .home__page [class*=section] {
    .more {
      font-size: 1.6rem;
    }
  }

  .section-payment {
    padding: 2rem 0;

    .payment {
      gap: 1rem 2.5rem;

      .items {
        gap: 2rem 2.5rem;
      }
    }
  }

}

@media only screen and (max-width: 480px) {
  body {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }

}


@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.ta-left,
.ta-left > * {
  text-align: left;
}

.ta-right,
.ta-right > * {
  text-align: right;
}

.ta-center,
.ta-center > * {
  text-align: center;
}

.overflow-h {
  overflow: hidden;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  display: none !important;
}

.clearfix {
  display: table;
}

.clearfix:after {
  content: "'. .'";
  display: block;
  word-spacing: 99in;
  height: 0;
  overflow: hidden;
  font-size: 0.13em;
  line-height: 0;
}

.color01 {
  color: #fff
}

.color02 {
  color: #000
}

.color03 {
  color: #00BBC6;
}

.color04 {
  color: #DBDBDB;
}

.color05 {
  color: #333;
}

.el-block {
  display: block !important;
}

.el-inline {
  display: inline !important;
}

.el-inline-block {
  display: inline-block !important;
}

.el-table {
  display: table !important;
}

.el-inline-table {
  display: inline-table !important;
}

.el-flex {
  display: flex !important;
}

.el-inline-flex {
  display: inline-flex !important;
}

.valign-t {
  vertical-align: top !important;
}

.valign-m {
  vertical-align: middle !important;
}

.valign-b {
  vertical-align: bottom !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.show-less-479,
.show-less-767,
.show-less-1000,
.show-less-1200 {
  display: none;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.chold01 {
  max-width: 860px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.ofi {
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.d-flex {
  display: -ms-flexbox;
  display: flex
}

.d-inline-flex {
  display: -ms-inline-flexbox;
  display: inline-flex
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row
}

.flex-column {
  -ms-flex-direction: column;
  flex-direction: column
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse
}

.flex-fill {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.flex-full {
  -ms-flex: 1 1 0;
  flex: 1 1 0
}

.flex-grow-0 {
  -ms-flex-positive: 0;
  flex-grow: 0
}

.flex-grow-1 {
  -ms-flex-positive: 1;
  flex-grow: 1
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1
}

.justify-content-start {
  -ms-flex-pack: start;
  justify-content: flex-start
}

.justify-content-end {
  -ms-flex-pack: end;
  justify-content: flex-end
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between
}

.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around
}

.align-items-start {
  -ms-flex-align: start;
  align-items: flex-start
}

.align-items-end {
  -ms-flex-align: end;
  align-items: flex-end
}

.align-items-center {
  -ms-flex-align: center;
  align-items: center
}

.align-items-baseline {
  -ms-flex-align: baseline;
  align-items: baseline
}

.align-items-stretch {
  -ms-flex-align: stretch;
  align-items: stretch
}

.align-content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start
}

.align-content-end {
  -ms-flex-line-pack: end;
  align-content: flex-end
}

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center
}

.align-content-between {
  -ms-flex-line-pack: justify;
  align-content: space-between
}

.align-content-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around
}

.align-content-stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch
}

.align-self-auto {
  -ms-flex-item-align: auto;
  align-self: auto
}

.align-self-start {
  -ms-flex-item-align: start;
  align-self: flex-start
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end
}

.align-self-center {
  -ms-flex-item-align: center;
  align-self: center
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch
}

.d-flex-xs {
  display: -ms-flexbox !important;
  display: flex !important
}

.d-inline-flex-xs {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important
}

.d-flex-xs > *[class*=col-] {
  float: none;
}

.rtl-page .d-flex-xs > *[class*=col-] {
  float: none;
}

/* grid */
.formitem {
  width: 100%;
}

*[class*="col-"] {
  min-height: 1px;
}

*[class*="col-xs-"] {
  float: left;
}

.rtl-page *[class*="col-xs-"] {
  float: right;
}

.colsgrid.reversed > *[class*="col-xs-"] {
  float: right;
}

.rtl-page .colsgrid.reversed > *[class*="col-xs-"] {
  float: left;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

*[class*="col-xs-pull"],
*[class*="col-xs-push"] {
  position: relative;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.colsgrid {
  margin-left: -15px;
  margin-right: -15px;
}

.colsgrid:after {
  content: "";
  display: block;
  clear: both;
}

.colsgrid > *[class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

.colsgrid2 {
  margin-left: -20px;
  margin-right: -20px;
}

.colsgrid2 > *[class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

.colsgrid3 {
  margin-left: -30px;
  margin-right: -30px;
}

.colsgrid3 > *[class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}


#wrapper[class*=page-] h1,
#wrapper[class*=page-] h2,
#wrapper[class*=page-] h3,
#wrapper[class*=page-] h4,
#wrapper[class*=page-] h5,
#wrapper[class*=page-] h6 {
  color: #ffffff;
}

/* all */
.decor {
  position: relative;
}

.decor:before {
  content: "";
  position: absolute;
  width: 284px;
  height: 332px;
  background: url("#{$site-domain}/Media/Image/bg-decor01.png") no-repeat;
  background-size: cover;
}

.decor.rt:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.line {
  height: 1px;
  display: block;
  border-top-width: 1px;
  border-color: rgba(199, 199, 199, 0.2);
  border-style: solid;
  overflow: hidden;
}

.line.dashed {
  border-style: dashed;
}

.line.dotted {
  border-style: dotted;
}

.more {
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  min-width: 142px;
}

.more > span {
  position: relative;
  padding: 0 25px 0 0;
}

.more > span:after {
  content: "\e907";
  font-family: 'icomoon';
  position: absolute;
  right: 0;
  top: 0;
  font-size: 15px;
  height: 15px;
  margin: auto 0;
}

.nice-image {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.nice-image img {
  position: relative;
  border-radius: 5px;
}

.image-position01 {
  margin-left: 20px;
}

.box {
  position: relative;
  background: #14222F;
  border: 1px solid #636262;
  color: #DBDBDB;
  border-radius: 3px;
  margin: 0 0 50px;
}

.box .box-heading {
  padding: 5px 30px;
}

.box .box-content {
  padding: 30px;
}


.box .box-heading:after,
.box .box-content:after {
  content: "";
  display: block;
  clear: both;
}

.box .box-content > *:last-child {
  margin-bottom: 0;
}

.box02 {
  background: #0F1821;
}

.box.nobdr {
  border-color: transparent;
  border: 0;
}

.box-blockquote {
  padding: 23px;
  flex-direction: row-reverse;
}

.box-blockquote .box-content {
  padding: 0 30px 0 45px;
}

.box-blockquote .box-content:before {
  content: "\e90f";
  font-family: 'icomoon';
  position: absolute;
  left: 25px;
  top: 30px;
  color: #00BBC6;
  font-size: 25px;
  line-height: 25px;
}

.box-blockquote .image img {
  /* position: absolute;
	left: 0;
	top: 0;
	width: 100%; */
  height: 100%;
  border-radius: 3px;
}

.box-blockquote q {
  display: block;
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
}

.box-blockquote cite {
  position: relative;
  float: right;
  font-size: 16px;
  color: #00BBC6;
  padding: 0 0 0 25px;
}

.box-blockquote cite:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 20px;
  height: 1px;
  background: #00BBC6;
}

.box-blockquote.flex-row {
  flex-direction: row;
}

.box-blockquote.flex-row .box-content {
  padding: 0 45px 0 30px;
}

.box-blockquote.flex-row .box-content:before {
  left: auto;
  right: 25px;
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.box-blockquote.flex-row cite {
  float: left;
  padding: 0 25px 0 0;
}

.box-blockquote.flex-row cite:after {
  left: auto;
  right: 0;
}

.boxes {
  padding-bottom: 25px;
}

.boxes.decor:before {
  left: -75px;
  bottom: 30px;
}

.boxes *[class*=col-] {
  margin-bottom: 25px;
}

.boxes .box {
  margin-bottom: 0;
  min-height: 100%;
  overflow: hidden;
  padding: 13px;
}

.boxes .box > * {
  position: relative;
}

.boxes .box .box-content {
  padding: 20px 10px 60px;
}

.boxes .box .image {
  position: relative;
  padding: 0 0 54.54545454545455%;
}

.boxes .box .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.boxes .box h3 {
  margin-bottom: 10px;
}

.boxes .box .btn-hold {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
}

.tabset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tabset li {
  display: inline-block;
  vertical-align: top;
}


.markets-news-head .filter {
  margin: -1px 0;
}

.signature {
  text-align: right;
  padding: 20px 0;
}


.str_wrap {
  overflow: hidden;
  width: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.str_move {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  cursor: move;
}

.str_move_clone {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  left: 100%;
  top: 0;
}

.str_vertical .str_move_clone {
  left: 0;
  top: 100%;
}

.str_down .str_move_clone {
  left: 0;
  bottom: 100%;
}

.str_vertical .str_move,
.str_down .str_move {
  white-space: normal;
  width: 100%;
}

.str_static .str_move,
.no_drag .str_move,
.noStop .str_move {
  cursor: inherit;
}

.str_wrap img {
  max-width: none !important;
}


.hidden-area {
  display: block;
  height: 0;
  overflow: hidden;
  text-indent: -9999px;
  width: 0;
}

.text_tooltip {
  background-color: #000;
  border: 1px solid #fff;
  color: #fff;
  display: none;
  font-size: 12px;
  padding: 10px 15px;
  width: 200px;
}

*[class*=section] {
  position: relative;
  overflow: hidden;
  color: #DBDBDB;
}

.bg[class*=section]:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url("#{$site-domain}/Media/Image/bg-w2.png") repeat-y 50% 0;
}

.bg[class*=section] > * {
  position: relative;
}

*[class*=section] h1,
*[class*=section] .h1 {
  color: #ffffff;
}

.section01 {
  padding: 162px 0 100px;
}

.hp-box {
  position: relative;
}

.hp-box:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #14222F;
  border-radius: 3px;
}

.hp-box > * {
  position: relative;
  z-index: 1;
}

.hp-image {
  position: relative;
  border: 4px solid transparent;
}

.hp-image:after {
  content: "";
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  border: 4px solid #00BBC6;
  border-radius: 3px;
}

.hp-image img {
  vertical-align: top;
  position: relative;
  z-index: 1;
}

.section01 .hp-image.decor:before {
  left: -80px;
  top: -90px;
}

.section01 .hp-image:after {
  top: 4px;
  right: -217px;
  left: 66px;
}

.section01 .hp-image > div {
  margin-top: -65px;
  margin-inline-start: -135px;
}

.section01 .hp-box {
  margin: 72px 0 0 -85px;
  padding: 70px;
}

.section01 .hp-box p {
  margin: 0;
}

.section01 .hp-box.decor:before {
  right: -72px;
  top: -72px;
}

.section01 h1,
.section01 .h1 {
  margin-bottom: 25px;
}

.section02 {
  background: #F4F3F3;
  padding: 100px 0 96px;
}

.section02 .layer {
  display: flex;
}

.section02 .hp-box {
  margin: 0;
  margin-inline-end: -15px;
  padding: 70px;
  z-index: 1;
}

.section02 .hp-box.decor:before {
  bottom: 75px;
  left: -70px;
}

.section02 h1,
.section02 .h1 {
  margin-bottom: 22px;
}

.section02 p {
  margin-bottom: 0;
}

.section02 .hp-image {
  margin-top: 135px;
  margin-inline-start: 70px;
}

.section02 .hp-image:after {
  left: -300px;
  right: 52px;
  top: -70px;
  bottom: 72px;
}

.section02 .hp-image.decor:before {
  right: -75px;
  top: -80px;
}

.section02 .hp-image > div {
  margin: 0;
  margin-inline-end: -60px;
}

.section03 {
  padding: 92px 0;
}

.section03 .layer {
  display: flex;
}

.section03 .hp-box {
  margin: 0;
  margin-inline-start: -85px;
  padding: 70px 70px 50px;
  z-index: 1;
}

.section03 .hp-box.decor:before {
  bottom: -95px;
  right: -70px;
}

.section03 h1,
.section03 .h1 {
  margin-bottom: 22px;
}

.section03 p {
  margin-bottom: 0;
}

.section03 .hp-image {
  margin-top: 114px;
}

.section03 .hp-image:after {
  top: -62px;
  left: 65px;
  bottom: 90px;
  right: -420px;
}

.section03 .hp-image.decor:before {
  left: -75px;
  top: 80px;
}

.section03 .hp-image > div {
  margin: -4px -6px;
}

.section04 {
  background: #F4F3F3;
  padding: 100px 0 145px;
}

.section04 .layer {
  display: flex;
}

.section04 .hp-box {
  margin-inline-end: -15px;
  margin-top: 115px;
  padding: 70px;
  z-index: 2;
}

.section04 .hp-box.decor:before {
  bottom: 75px;
  left: -70px;
}

.section04 h1,
.section04 .h1 {
  margin-bottom: 22px;
}

.section04 p {
  margin-bottom: 0;
}

.section04 .hp-image {

}

.section04 .hp-image:after {
  left: -320px;
  right: 168px;
  top: 52px;
  bottom: -75px;
}

.section04 .hp-image.decor:before {
  right: -75px;
  top: -80px;
}

.section04 .hp-image > div {
  margin-inline-start: -55px;
  margin-inline-end: -3px;
  margin-top: -10px;
}

.section05 {
  background: url("#{$site-domain}/Media/Image/bg-hp-section03.png") no-repeat 50% 0;
  background-size: cover;
  padding: 100px 0;
  text-align: center;
}

.section05 h1,
.section05 .h1 {
  margin-bottom: 35px;
  font-size: 38px;
}

.section05 p {
  margin: 0 auto 10px;
  max-width: 1163px;
}

.section05 p strong {
  font-size: 21px;
  font-weight: 600;
  font-variant: all-small-caps;
  letter-spacing: 0.5px;
}

.section05 .btn-holder {
  padding-top: 24px;
}

.section05 .btn-holder .button {
  text-transform: uppercase;
}

.section06 {
  padding: 100px 0 160px;
}

.section06 h1,
.section06 .h1 {
  margin-bottom: 35px;
  color: #14222F;
  text-align: center;
}

.section06 .decor01,
.section06 .decor02 {
  position: relative;
  max-width: 998px;
  margin: 0 auto;
}

.section06 .decor02 {
  height: 455px;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.20);
}

.section06 .decor01:before,
.section06 .decor01:after,
.section06 .decor02:before,
.section06 .decor02:after {
  content: '';
  position: absolute;
  display: block;
}

.section06 .decor01:before,
.section06 .decor01:after {
  border: 4px solid #00BBC6;
  border-radius: 3px;
}


.section06 .decor01:before {
  top: -32px;
  left: -62px;
  width: 376px;
  height: 326px;
}

.section06 .decor01:after {
  right: -157px;
  bottom: -60px;
  width: 70%;
  height: 261px;
}

.section06 .decor02:before,
.section06 .decor02:after {

  width: 284px;
  height: 332px;
  background: url("#{$site-domain}/Media/Image/bg-decor01.png") no-repeat;
  background-size: cover;
}

.section06 .decor02:before {
  bottom: -60px;
  left: -157px;
}

.section06 .decor02:after {
  right: -134px;
  top: -118px;
  transform: rotate(90deg);
}

.section07 {
  background: url("#{$site-domain}/Media/Image/bg-hp-section03.png") no-repeat 50% 0;
  background-size: cover;
  padding: 100px 0;
}

.section07 .heading {
  text-align: center;
}

.section07 .heading .h1 {
  margin-bottom: 24px;
}

.section07 .heading p {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  font-variant: all-small-caps;
  margin-bottom: 35px;
}

.section07 .items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.section07 .item {
  margin: 0;
  display: flex;
  gap: 30px;
  padding: 29px;
}

.section07 .item .hp-icon {
  flex-shrink: 0;
}

.section07 .item h3 {
  line-height: 26px;
  margin-bottom: 10px;
  color: #DBDBDB;
}

.section07 .item p {
  margin: 0;
}


.section08 {
  padding: 70px 0;
  background: #F8F8F8;
}

.section08 .items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section08 .images {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 45px;
  padding-inline-end: 33px;
}

.section08 .h1 {
  color: #14222F;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 32px;
}


.hp-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #00BBC6;

  font-size: 44px;
  line-height: 44px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.recaptcha {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 304px;
  overflow: hidden;
}

.g-recaptcha {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.rtl-page .g-recaptcha {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.justify-content-center > .g-recaptcha,
.rtl-page .justify-content-center > .g-recaptcha {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.footer-content {
  position: relative;
  background: url("#{$site-domain}/Media/Image/bg-w2.png") repeat-y 50% 0;
}


.payment {
  padding: 50px 0 35px;
  margin: 0 -15px;
  gap: 4rem;
}

.payment p {
  margin-bottom: 20px;
}

.payment .items {
  display: flex;
  justify-content: space-between;
}

.payment .items > * {
  display: inline-block;
  vertical-align: top;
  margin: 10px 15px;
}

.social {
  font-size: 0;
}

.social a {
  display: inline-block;
  vertical-align: top;
  position: relative;
  color: #fff;
  width: 61px;
  height: 61px;
  line-height: 55px;
  border: 3px solid #00BBC6;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
}

.social a:hover {
  background: #00BBC6;
}

.social a:before {
  font-weight: 400;
  font-family: 'icomoon';
  font-size: 20px;
}

.social .fb:before {
  content: "\61";
}

.social .tw:before {
  content: "\62";
}

.social .in:before {
  content: "\63";
}

.footer-row .social {
  width: 61px;
  margin: 0 0 0 15px;
}

.footer-row .social a {
  margin: 5px 0 15px;
}

/* extra panel*/
.extra-panel {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0 0 0 -850px;
  z-index: 100;
  font: 600 16px 'Montserrat', sans-serif;
  color: #DBDBDB;
  width: 62px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.extra-panel .social a {
  margin: 10px 0;
  border-width: 2px;
  width: 62px;
  height: 62px;
  line-height: 58px;
}

.extra-panel > span {
  float: left;
  writing-mode: tb-rl;
  margin: 10px 0;
  display: block;
  white-space: nowrap;
  line-height: 61px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* popups */
.popup-overlay {
  display: none;
}

.popup {
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: none;
  min-width: 320px;
}

.rtl-page .direction-area {
  direction: rtl;
}


/* auxiliary */
.rtl-page .fl-l.reversed {
  float: right;
}

.rtl-page .fl-r.reversed {
  float: left;
}

.rtl-page .cl-l.reversed {
  clear: left;
}

.rtl-page .cl-r.reversed {
  clear: right;
}

.rtl-page .ta-left.reversed,
.rtl-page .ta-left.reversed > * {
  text-align: right;
}

.rtl-page .ta-right.reversed,
.rtl-page .ta-right.reversed > * {
  text-align: left;
}

/* grid */
.rtl-page *[class*="col-xs-offset-"] {
  margin-left: 0;
}

.rtl-page .col-xs-offset-12 {
  margin-right: 100%;
}

.rtl-page .col-xs-offset-11 {
  margin-right: 91.66666667%;
}

.rtl-page .col-xs-offset-10 {
  margin-right: 83.33333333%;
}

.rtl-page .col-xs-offset-9 {
  margin-right: 75%;
}

.rtl-page .col-xs-offset-8 {
  margin-right: 66.66666667%;
}

.rtl-page .col-xs-offset-7 {
  margin-right: 58.33333333%;
}

.rtl-page .col-xs-offset-6 {
  margin-right: 50%;
}

.rtl-page .col-xs-offset-5 {
  margin-right: 41.66666667%;
}

.rtl-page .col-xs-offset-4 {
  margin-right: 33.33333333%;
}

.rtl-page .col-xs-offset-3 {
  margin-right: 25%;
}

.rtl-page .col-xs-offset-2 {
  margin-right: 16.66666667%;
}

.rtl-page .col-xs-offset-1 {
  margin-right: 8.33333333%;
}

.rtl-page .col-xs-offset-0 {
  margin-right: 0;
}

.rtl-page *[class*="col-xs-pull-"] {
  right: auto;
}

.rtl-page .col-xs-pull-12 {
  left: 100%;
}

.rtl-page .col-xs-pull-11 {
  left: 91.66666667%;
}

.rtl-page .col-xs-pull-10 {
  left: 83.33333333%;
}

.rtl-page .col-xs-pull-9 {
  left: 75%;
}

.rtl-page .col-xs-pull-8 {
  left: 66.66666667%;
}

.rtl-page .col-xs-pull-7 {
  left: 58.33333333%;
}

.rtl-page .col-xs-pull-6 {
  left: 50%;
}

.rtl-page .col-xs-pull-5 {
  left: 41.66666667%;
}

.rtl-page .col-xs-pull-4 {
  left: 33.33333333%;
}

.rtl-page .col-xs-pull-3 {
  left: 25%;
}

.rtl-page .col-xs-pull-2 {
  left: 16.66666667%;
}

.rtl-page .col-xs-pull-1 {
  left: 8.33333333%;
}

.rtl-page .col-xs-pull-0 {
  left: auto;
}

.rtl-page *[class*="col-xs-push-"] {
  left: auto;
}

.rtl-page .col-xs-push-12 {
  right: 100%;
}

.rtl-page .col-xs-push-11 {
  right: 91.66666667%;
}

.rtl-page .col-xs-push-10 {
  right: 83.33333333%;
}

.rtl-page .col-xs-push-9 {
  right: 75%;
}

.rtl-page .col-xs-push-8 {
  right: 66.66666667%;
}

.rtl-page .col-xs-push-7 {
  right: 58.33333333%;
}

.rtl-page .col-xs-push-6 {
  right: 50%;
}

.rtl-page .col-xs-push-5 {
  right: 41.66666667%;
}

.rtl-page .col-xs-push-4 {
  right: 33.33333333%;
}

.rtl-page .col-xs-push-3 {
  right: 25%;
}

.rtl-page .col-xs-push-2 {
  right: 16.66666667%;
}

.rtl-page .col-xs-push-1 {
  right: 8.33333333%;
}

.rtl-page .col-xs-push-0 {
  right: auto;
}


/* elements */


/* all */
.rtl-page .more > span {
  padding: 0 0 0 25px;
}

.rtl-page .more > span:after {
  content: "\e906";
  right: auto;
  left: 0;
}

.rtl-page .image-position01 {
  margin-left: 0;
  margin-right: 20px;
}

.rtl-page .boxes.decor:before {
  left: auto;
  right: -75px;
}

.rtl-page .box-blockquote .box-content:before {
  left: auto;
  right: 25px;
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.rtl-page .box-blockquote .box-content {
  padding: 0 45px 0 30px;
}

.rtl-page .box-blockquote cite {
  float: left;
  padding: 0 25px 0 0;
}

.rtl-page .box-blockquote cite:after {
  left: auto;
  right: 0;
}

.rtl-page .box-blockquote.flex-row .box-content {
  padding: 0 30px 0 45px;
}

.rtl-page .box-blockquote.flex-row .box-content:before {
  right: auto;
  left: 25px;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.rtl-page .box-blockquote.flex-row cite {
  float: right;
  padding: 0 0 0 25px;
}

.rtl-page .box-blockquote.flex-row cite:after {
  right: auto;
  left: 0;
}

.rtl-page .signature {
  text-align: left;
}


.rtl-page .section01 .hp-image.decor:before {
  left: auto;
  right: -50px;
}

.rtl-page .section01 .hp-image:after {
  right: -4px;
  left: -148px;
}

.rtl-page .section01 .hp-box {
  margin: 72px -85px 0 0;
  padding: 75px 85px 55px 35px;
}

.rtl-page .section01 .hp-box.decor:before {
  right: auto;
  left: -72px;
  top: -72px;
}

.rtl-page .section02 .hp-box {
  margin: 0 0 0 15px;
}

.rtl-page .section02 .hp-box.decor:before {
  left: auto;
  right: -70px;
}

.rtl-page .section02 .hp-image {
  margin: 80px -19px 80px 0;
}

.rtl-page .section02 .hp-image.decor:before {
  right: auto;
  left: -75px;
}

.rtl-page .section02 .hp-image > div {
  margin: 163px -75px 39px -115px
}

.rtl-page .section03 {
  background: url("#{$site-domain}/Media/Image/bg-hp-section03-rtl.png") no-repeat 50% 0;
  background-size: cover;
}

.rtl-page .section04 .aside {
  margin: 0 0 0 30px;
}


.rtl-page .section04 .image {
  margin: 0 0 0 -37px;
}

.rtl-page .section04 .image:after {
  right: 145px;
  left: -35px;
}

.rtl-page .section04 .image:before {
  right: auto;
  left: -10px;
}

.rtl-page .section05 {
  background: url("#{$site-domain}/Media/Image/bg-hp-section05-rtl.png") no-repeat 50% 0;
  background-size: cover;
}

.rtl-page .section05 .items {
  padding: 0 15px 0 0;
}

.rtl-page .section06 .hp-box {
  padding: 80px 110px 55px 50px;
  margin: 0 -85px 0 0;
}

.rtl-page .section06 .hp-box.decor:before {
  right: auto;
  left: -75px;
}

.rtl-page .section06 .hp-image {
  margin: 80px 0 0 -132px;
}

.rtl-page .section06 .hp-image:before {
  left: auto;
  right: -75px;
}

.rtl-page .section06 .hp-image > div {
  margin: 45px 45px -45px 178px;
}

.rtl-page .section06 .awards .item .image {
  margin: 0 0 0 30px;
}


/* extra panel*/
.rtl-page .extra-panel {
  left: auto;
  right: 50%;
  margin: 0 -850px 0 0;
  direction: rtl;
}

.rtl-page .extra-panel > span {
  float: right;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

/* popups */
.rtl-page .popup {
  direction: rtl;
}

.rtl-page .popup .close {
  left: auto;
  right: 10px;
}

/*extra margins*/
/*top*/
.mt0 {
  margin-top: 0 !important;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt45 {
  margin-top: 45px;
}

.mt50 {
  margin-top: 50px;
}

.mt55 {
  margin-top: 55px;
}

.mt60 {
  margin-top: 60px;
}

.mt65 {
  margin-top: 65px;
}

.mt70 {
  margin-top: 70px;
}

.mt75 {
  margin-top: 75px;
}

.mt80 {
  margin-top: 80px;
}

.mt85 {
  margin-top: 85px;
}

.mt90 {
  margin-top: 90px;
}

.mt95 {
  margin-top: 95px;
}

.mt100 {
  margin-top: 100px;
}

.mtm5 {
  margin-top: -5px;
}

.mtm10 {
  margin-top: -10px;
}

.mtm15 {
  margin-top: -15px;
}

.mtm20 {
  margin-top: -20px;
}

.mtm25 {
  margin-top: -25px;
}

.mtm30 {
  margin-top: -30px;
}

.mtm35 {
  margin-top: -35px;
}

.mtm40 {
  margin-top: -40px;
}

.mtm45 {
  margin-top: -45px;
}

.mtm50 {
  margin-top: -50px;
}

.mtm55 {
  margin-top: -55px;
}

.mtm60 {
  margin-top: -60px;
}

.mtm65 {
  margin-top: -65px;
}

.mtm70 {
  margin-top: -70px;
}

.mtm75 {
  margin-top: -75px;
}

.mtm80 {
  margin-top: -80px;
}

.mtm85 {
  margin-top: -85px;
}

.mtm90 {
  margin-top: -90px;
}

.mtm95 {
  margin-top: -95px;
}

.mtm100 {
  margin-top: -100px;
}

/*bottom*/
.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb55 {
  margin-bottom: 55px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb65 {
  margin-bottom: 65px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb75 {
  margin-bottom: 75px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb85 {
  margin-bottom: 85px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb95 {
  margin-bottom: 95px;
}

.mb100 {
  margin-bottom: 100px;
}

.mbm5 {
  margin-bottom: -5px;
}

.mbm10 {
  margin-bottom: -10px;
}

.mbm15 {
  margin-bottom: -15px;
}

.mbm20 {
  margin-bottom: -20px;
}

.mbm25 {
  margin-bottom: -25px;
}

.mbm30 {
  margin-bottom: -30px;
}

.mbm35 {
  margin-bottom: -35px;
}

.mbm40 {
  margin-bottom: -40px;
}

.mbm45 {
  margin-bottom: -45px;
}

.mbm50 {
  margin-bottom: -50px;
}

.mbm55 {
  margin-bottom: -55px;
}

.mbm60 {
  margin-bottom: -60px;
}

.mbm65 {
  margin-bottom: -65px;
}

.mbm70 {
  margin-bottom: -70px;
}

.mbm75 {
  margin-bottom: -75px;
}

.mbm80 {
  margin-bottom: -80px;
}

.mbm85 {
  margin-bottom: -85px;
}

.mbm90 {
  margin-bottom: -90px;
}

.mbm95 {
  margin-bottom: -95px;
}

.mbm100 {
  margin-bottom: -100px;
}

/*left*/
.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.ml35 {
  margin-left: 35px;
}

.ml40 {
  margin-left: 40px;
}

.ml45 {
  margin-left: 45px;
}

.ml50 {
  margin-left: 50px;
}

.ml55 {
  margin-left: 55px;
}

.ml60 {
  margin-left: 60px;
}

.ml65 {
  margin-left: 65px;
}

.ml70 {
  margin-left: 70px;
}

.ml75 {
  margin-left: 75px;
}

.ml80 {
  margin-left: 80px;
}

.ml85 {
  margin-left: 85px;
}

.ml90 {
  margin-left: 90px;
}

.ml95 {
  margin-left: 95px;
}

.ml100 {
  margin-left: 100px;
}

.mlm5 {
  margin-left: -5px;
}

.mlm10 {
  margin-left: -10px;
}

.mlm15 {
  margin-left: -15px;
}

.mlm20 {
  margin-left: -20px;
}

.mlm25 {
  margin-left: -25px;
}

.mlm30 {
  margin-left: -30px;
}

.mlm35 {
  margin-left: -35px;
}

.mlm40 {
  margin-left: -40px;
}

.mlm45 {
  margin-left: -45px;
}

.mlm50 {
  margin-left: -50px;
}

.mlm55 {
  margin-left: -55px;
}

.mlm60 {
  margin-left: -60px;
}

.mlm65 {
  margin-left: -65px;
}

.mlm70 {
  margin-left: -70px;
}

.mlm75 {
  margin-left: -75px;
}

.mlm80 {
  margin-left: -80px;
}

.mlm85 {
  margin-left: -85px;
}

.mlm90 {
  margin-left: -90px;
}

.mlm95 {
  margin-left: -95px;
}

.mlm100 {
  margin-left: -100px;
}

.rtl-page .ml5.reversed {
  margin-left: 0;
  margin-right: 5px;
}

.rtl-page .ml10.reversed {
  margin-left: 0;
  margin-right: 10px;
}

.rtl-page .ml15.reversed {
  margin-left: 0;
  margin-right: 15px;
}

.rtl-page .ml20.reversed {
  margin-left: 0;
  margin-right: 20px;
}

.rtl-page .ml25.reversed {
  margin-left: 0;
  margin-right: 25px;
}

.rtl-page .ml30.reversed {
  margin-left: 0;
  margin-right: 30px;
}

.rtl-page .ml35.reversed {
  margin-left: 0;
  margin-right: 35px;
}

.rtl-page .ml40.reversed {
  margin-left: 0;
  margin-right: 40px;
}

.rtl-page .ml45.reversed {
  margin-left: 0;
  margin-right: 45px;
}

.rtl-page .ml50.reversed {
  margin-left: 0;
  margin-right: 50px;
}

.rtl-page .ml55.reversed {
  margin-left: 0;
  margin-right: 55px;
}

.rtl-page .ml60.reversed {
  margin-left: 0;
  margin-right: 60px;
}

.rtl-page .ml65.reversed {
  margin-left: 0;
  margin-right: 65px;
}

.rtl-page .ml70.reversed {
  margin-left: 0;
  margin-right: 70px;
}

.rtl-page .ml75.reversed {
  margin-left: 0;
  margin-right: 75px;
}

.rtl-page .ml80.reversed {
  margin-left: 0;
  margin-right: 80px;
}

.rtl-page .ml85.reversed {
  margin-left: 0;
  margin-right: 85px;
}

.rtl-page .ml90.reversed {
  margin-left: 0;
  margin-right: 90px;
}

.rtl-page .ml95.reversed {
  margin-left: 0;
  margin-right: 95px;
}

.rtl-page .ml100.reversed {
  margin-left: 0;
  margin-right: 100px;
}

.rtl-page .mlm5.reversed {
  margin-left: 0;
  margin-right: -5px;
}

.rtl-page .mlm10.reversed {
  margin-left: 0;
  margin-right: -10px;
}

.rtl-page .mlm15.reversed {
  margin-left: 0;
  margin-right: -15px;
}

.rtl-page .mlm20.reversed {
  margin-left: 0;
  margin-right: -20px;
}

.rtl-page .mlm25.reversed {
  margin-left: 0;
  margin-right: -25px;
}

.rtl-page .mlm30.reversed {
  margin-left: 0;
  margin-right: -30px;
}

.rtl-page .mlm35.reversed {
  margin-left: 0;
  margin-right: -35px;
}

.rtl-page .mlm40.reversed {
  margin-left: 0;
  margin-right: -40px;
}

.rtl-page .mlm45.reversed {
  margin-left: 0;
  margin-right: -45px;
}

.rtl-page .mlm50.reversed {
  margin-left: 0;
  margin-right: -50px;
}

.rtl-page .mlm55.reversed {
  margin-left: 0;
  margin-right: -55px;
}

.rtl-page .mlm60.reversed {
  margin-left: 0;
  margin-right: -60px;
}

.rtl-page .mlm65.reversed {
  margin-left: 0;
  margin-right: -65px;
}

.rtl-page .mlm70.reversed {
  margin-left: 0;
  margin-right: -70px;
}

.rtl-page .mlm75.reversed {
  margin-left: 0;
  margin-right: -75px;
}

.rtl-page .mlm80.reversed {
  margin-left: 0;
  margin-right: -80px;
}

.rtl-page .mlm85.reversed {
  margin-left: 0;
  margin-right: -85px;
}

.rtl-page .mlm90.reversed {
  margin-left: 0;
  margin-right: -90px;
}

.rtl-page .mlm95.reversed {
  margin-left: 0;
  margin-right: -95px;
}

.rtl-page .mlm100.reversed {
  margin-left: 0;
  margin-right: -100px;
}

/*right*/
.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr25 {
  margin-right: 25px;
}

.mr30 {
  margin-right: 30px;
}

.mr35 {
  margin-right: 35px;
}

.mr40 {
  margin-right: 40px;
}

.mr45 {
  margin-right: 45px;
}

.mr50 {
  margin-right: 50px;
}

.mr55 {
  margin-right: 55px;
}

.mr60 {
  margin-right: 60px;
}

.mr65 {
  margin-right: 65px;
}

.mr70 {
  margin-right: 70px;
}

.mr75 {
  margin-right: 75px;
}

.mr80 {
  margin-right: 80px;
}

.mr85 {
  margin-right: 85px;
}

.mr90 {
  margin-right: 90px;
}

.mr95 {
  margin-right: 95px;
}

.mr100 {
  margin-right: 100px;
}

.mrm5 {
  margin-right: -5px;
}

.mrm10 {
  margin-right: -10px;
}

.mrm15 {
  margin-right: -15px;
}

.mrm20 {
  margin-right: -20px;
}

.mrm25 {
  margin-right: -25px;
}

.mrm30 {
  margin-right: -30px;
}

.mrm35 {
  margin-right: -35px;
}

.mrm40 {
  margin-right: -40px;
}

.mrm45 {
  margin-right: -45px;
}

.mrm50 {
  margin-right: -50px;
}

.mrm55 {
  margin-right: -55px;
}

.mrm60 {
  margin-right: -60px;
}

.mrm65 {
  margin-right: -65px;
}

.mrm70 {
  margin-right: -70px;
}

.mrm75 {
  margin-right: -75px;
}

.mrm80 {
  margin-right: -80px;
}

.mrm85 {
  margin-right: -85px;
}

.mrm90 {
  margin-right: -90px;
}

.mrm95 {
  margin-right: -95px;
}

.mrm100 {
  margin-right: -100px;
}

.rtl-page .mr5.reversed {
  margin-left: 5px;
}

.rtl-page .mr10.reversed {
  margin-right: 0;
  margin-left: 10px;
}

.rtl-page .mr15.reversed {
  margin-right: 0;
  margin-left: 15px;
}

.rtl-page .mr20.reversed {
  margin-right: 0;
  margin-left: 20px;
}

.rtl-page .mr25.reversed {
  margin-right: 0;
  margin-left: 25px;
}

.rtl-page .mr30.reversed {
  margin-right: 0;
  margin-left: 30px;
}

.rtl-page .mr35.reversed {
  margin-right: 0;
  margin-left: 35px;
}

.rtl-page .mr40.reversed {
  margin-right: 0;
  margin-left: 40px;
}

.rtl-page .mr45.reversed {
  margin-right: 0;
  margin-left: 45px;
}

.rtl-page .mr50.reversed {
  margin-right: 0;
  margin-left: 50px;
}

.rtl-page .mr55.reversed {
  margin-right: 0;
  margin-left: 55px;
}

.rtl-page .mr60.reversed {
  margin-right: 0;
  margin-left: 60px;
}

.rtl-page .mr65.reversed {
  margin-right: 0;
  margin-left: 65px;
}

.rtl-page .mr70.reversed {
  margin-right: 0;
  margin-left: 70px;
}

.rtl-page .mr75.reversed {
  margin-right: 0;
  margin-left: 75px;
}

.rtl-page .mr80.reversed {
  margin-right: 0;
  margin-left: 80px;
}

.rtl-page .mr85.reversed {
  margin-right: 0;
  margin-left: 85px;
}

.rtl-page .mr90.reversed {
  margin-right: 0;
  margin-left: 90px;
}

.rtl-page .mr95.reversed {
  margin-right: 0;
  margin-left: 95px;
}

.rtl-page .mr100.reversed {
  margin-right: 0;
  margin-left: 100px;
}

.rtl-page .mrm5.reversed {
  margin-right: 0;
  margin-left: -5px;
}

.rtl-page .mrm10.reversed {
  margin-right: 0;
  margin-left: -10px;
}

.rtl-page .mrm15.reversed {
  margin-right: 0;
  margin-left: -15px;
}

.rtl-page .mrm20.reversed {
  margin-right: 0;
  margin-left: -20px;
}

.rtl-page .mrm25.reversed {
  margin-right: 0;
  margin-left: -25px;
}

.rtl-page .mrm30.reversed {
  margin-right: 0;
  margin-left: -30px;
}

.rtl-page .mrm35.reversed {
  margin-right: 0;
  margin-left: -35px;
}

.rtl-page .mrm40.reversed {
  margin-right: 0;
  margin-left: -40px;
}

.rtl-page .mrm45.reversed {
  margin-right: 0;
  margin-left: -45px;
}

.rtl-page .mrm50.reversed {
  margin-right: 0;
  margin-left: -50px;
}

.rtl-page .mrm55.reversed {
  margin-right: 0;
  margin-left: -55px;
}

.rtl-page .mrm60.reversed {
  margin-right: 0;
  margin-left: -60px;
}

.rtl-page .mrm65.reversed {
  margin-right: 0;
  margin-left: -65px;
}

.rtl-page .mrm70.reversed {
  margin-right: 0;
  margin-left: -70px;
}

.rtl-page .mrm75.reversed {
  margin-right: 0;
  margin-left: -75px;
}

.rtl-page .mrm80.reversed {
  margin-right: 0;
  margin-left: -80px;
}

.rtl-page .mrm85.reversed {
  margin-right: 0;
  margin-left: -85px;
}

.rtl-page .mrm90.reversed {
  margin-right: 0;
  margin-left: -90px;
}

.rtl-page .mrm95.reversed {
  margin-right: 0;
  margin-left: -95px;
}

.rtl-page .mrm100.reversed {
  margin-right: 0;
  margin-left: -100px;
}

.acc-table-wrapper {
  display: none;
}

.acc-table-wrapper .item {
  border-radius: 3px;
  border: 1px solid #636262;
  background: #14222F;
  margin-bottom: 20px;
}

.acc-table-wrapper .item .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 39px;
  background: #0F1821;
  border-radius: 3px 3px 0 0;
  padding: 8px 20px;
  color: #00BBC6;
  text-align: center;
}

.acc-table-wrapper .item .heading .acc-number {
  display: flex;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.acc-table-wrapper .item .heading .acc-number:before {
  content: '\e91f';
  font-family: 'icomoon';
  font-size: 14px;
  line-height: 14px;
}

.acc-table-wrapper .item .heading .acc-number.no-icon:before {
  content: none;
}

.acc-table-wrapper .item .heading .currency {
  padding: 3px 8px;
  min-width: 42px;
  min-height: 23px;
  display: block;
  border-radius: 5px;
  background: #01CACC;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize;
}

.acc-table-wrapper .item .body {
  padding: 15px 20px 7px;
}

.acc-table-wrapper .item .body .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 37px;
  padding: 0 15px;
  border-radius: 5px;
  margin-bottom: 2px;
  font-size: 13px;
  line-height: 17px;
  color: #DBDBDB;
}

.acc-table-wrapper .item .body .row:nth-child(2n + 1) {
  background: #283643;
}

.acc-table-wrapper .item .body .row:after {
  content: none;
}

.acc-table-wrapper .item .body .row:last-child {
  margin-bottom: 0;
}

.acc-table-wrapper .item .body .row .title {
  font-weight: 700;
}

/* media queries */
/* grid */
@media (min-width: 480px) {
  *[class*="col-sm-"] {
    float: left;
  }
  .rtl-page *[class*="col-sm-"] {
    float: right;
  }
  .colsgrid.reversed > *[class*="col-sm-"] {
    float: right;
  }
  .rtl-page .colsgrid.reversed > *[class*="col-sm-"] {
    float: left;
  }
  .d-flex-sm {
    display: -ms-flexbox !important;
    display: flex !important
  }
  .d-inline-flex-sm {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
  .d-flex-sm > *[class*=col-] {
    float: none;
  }
  .rtl-page .d-flex-sm > *[class*=col-] {
    float: none;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-12 {
    width: 100%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }

  *[class*="col-sm-pull"],
  *[class*="col-sm-push"] {
    position: relative;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }

  .rtl-page *[class*="col-sm-offset-"] {
    margin-left: 0;
  }
  .rtl-page .col-sm-offset-12 {
    margin-right: 100%;
  }
  .rtl-page .col-sm-offset-11 {
    margin-right: 91.66666667%;
  }
  .rtl-page .col-sm-offset-10 {
    margin-right: 83.33333333%;
  }
  .rtl-page .col-sm-offset-9 {
    margin-right: 75%;
  }
  .rtl-page .col-sm-offset-8 {
    margin-right: 66.66666667%;
  }
  .rtl-page .col-sm-offset-7 {
    margin-right: 58.33333333%;
  }
  .rtl-page .col-sm-offset-6 {
    margin-right: 50%;
  }
  .rtl-page .col-sm-offset-5 {
    margin-right: 41.66666667%;
  }
  .rtl-page .col-sm-offset-4 {
    margin-right: 33.33333333%;
  }
  .rtl-page .col-sm-offset-3 {
    margin-right: 25%;
  }
  .rtl-page .col-sm-offset-2 {
    margin-right: 16.66666667%;
  }
  .rtl-page .col-sm-offset-1 {
    margin-right: 8.33333333%;
  }
  .rtl-page .col-sm-offset-0 {
    margin-right: 0;
  }

  .rtl-page *[class*="col-sm-pull-"] {
    right: auto;
  }
  .rtl-page .col-sm-pull-12 {
    left: 100%;
  }
  .rtl-page .col-sm-pull-11 {
    left: 91.66666667%;
  }
  .rtl-page .col-sm-pull-10 {
    left: 83.33333333%;
  }
  .rtl-page .col-sm-pull-9 {
    left: 75%;
  }
  .rtl-page .col-sm-pull-8 {
    left: 66.66666667%;
  }
  .rtl-page .col-sm-pull-7 {
    left: 58.33333333%;
  }
  .rtl-page .col-sm-pull-6 {
    left: 50%;
  }
  .rtl-page .col-sm-pull-5 {
    left: 41.66666667%;
  }
  .rtl-page .col-sm-pull-4 {
    left: 33.33333333%;
  }
  .rtl-page .col-sm-pull-3 {
    left: 25%;
  }
  .rtl-page .col-sm-pull-2 {
    left: 16.66666667%;
  }
  .rtl-page .col-sm-pull-1 {
    left: 8.33333333%;
  }
  .rtl-page .col-sm-pull-0 {
    left: auto;
  }

  .rtl-page *[class*="col-sm-push-"] {
    left: auto;
  }
  .rtl-page .col-sm-push-12 {
    right: 100%;
  }
  .rtl-page .col-sm-push-11 {
    right: 91.66666667%;
  }
  .rtl-page .col-sm-push-10 {
    right: 83.33333333%;
  }
  .rtl-page .col-sm-push-9 {
    right: 75%;
  }
  .rtl-page .col-sm-push-8 {
    right: 66.66666667%;
  }
  .rtl-page .col-sm-push-7 {
    right: 58.33333333%;
  }
  .rtl-page .col-sm-push-6 {
    right: 50%;
  }
  .rtl-page .col-sm-push-5 {
    right: 41.66666667%;
  }
  .rtl-page .col-sm-push-4 {
    right: 33.33333333%;
  }
  .rtl-page .col-sm-push-3 {
    right: 25%;
  }
  .rtl-page .col-sm-push-2 {
    right: 16.66666667%;
  }
  .rtl-page .col-sm-push-1 {
    right: 8.33333333%;
  }
  .rtl-page .col-sm-push-0 {
    right: auto;
  }
}

@media (min-width: 768px) {
  *[class*="col-md-"] {
    float: left;
  }
  .rtl-page *[class*="col-md-"] {
    float: right;
  }
  .colsgrid.reversed > *[class*="col-md-"] {
    float: right;
  }
  .rtl-page .colsgrid.reversed > *[class*="col-md-"] {
    float: left;
  }
  .d-flex-md {
    display: -ms-flexbox !important;
    display: flex !important
  }
  .d-inline-flex-md {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
  .d-flex-md > *[class*=col-] {
    float: none;
  }
  .rtl-page .d-flex-md > *[class*=col-] {
    float: none;
  }

  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-12 {
    width: 100%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }

  *[class*="col-md-pull"],
  *[class*="col-md-push"] {
    position: relative;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }

  .rtl-page *[class*="col-md-offset-"] {
    margin-left: 0;
  }
  .rtl-page .col-md-offset-12 {
    margin-right: 100%;
  }
  .rtl-page .col-md-offset-11 {
    margin-right: 91.66666667%;
  }
  .rtl-page .col-md-offset-10 {
    margin-right: 83.33333333%;
  }
  .rtl-page .col-md-offset-9 {
    margin-right: 75%;
  }
  .rtl-page .col-md-offset-8 {
    margin-right: 66.66666667%;
  }
  .rtl-page .col-md-offset-7 {
    margin-right: 58.33333333%;
  }
  .rtl-page .col-md-offset-6 {
    margin-right: 50%;
  }
  .rtl-page .col-md-offset-5 {
    margin-right: 41.66666667%;
  }
  .rtl-page .col-md-offset-4 {
    margin-right: 33.33333333%;
  }
  .rtl-page .col-md-offset-3 {
    margin-right: 25%;
  }
  .rtl-page .col-md-offset-2 {
    margin-right: 16.66666667%;
  }
  .rtl-page .col-md-offset-1 {
    margin-right: 8.33333333%;
  }
  .rtl-page .col-md-offset-0 {
    margin-right: 0;
  }

  .rtl-page *[class*="col-md-pull-"] {
    right: auto;
  }
  .rtl-page .col-md-pull-12 {
    left: 100%;
  }
  .rtl-page .col-md-pull-11 {
    left: 91.66666667%;
  }
  .rtl-page .col-md-pull-10 {
    left: 83.33333333%;
  }
  .rtl-page .col-md-pull-9 {
    left: 75%;
  }
  .rtl-page .col-md-pull-8 {
    left: 66.66666667%;
  }
  .rtl-page .col-md-pull-7 {
    left: 58.33333333%;
  }
  .rtl-page .col-md-pull-6 {
    left: 50%;
  }
  .rtl-page .col-md-pull-5 {
    left: 41.66666667%;
  }
  .rtl-page .col-md-pull-4 {
    left: 33.33333333%;
  }
  .rtl-page .col-md-pull-3 {
    left: 25%;
  }
  .rtl-page .col-md-pull-2 {
    left: 16.66666667%;
  }
  .rtl-page .col-md-pull-1 {
    left: 8.33333333%;
  }
  .rtl-page .col-md-pull-0 {
    left: auto;
  }

  .rtl-page *[class*="col-md-push-"] {
    left: auto;
  }
  .rtl-page .col-md-push-12 {
    right: 100%;
  }
  .rtl-page .col-md-push-11 {
    right: 91.66666667%;
  }
  .rtl-page .col-md-push-10 {
    right: 83.33333333%;
  }
  .rtl-page .col-md-push-9 {
    right: 75%;
  }
  .rtl-page .col-md-push-8 {
    right: 66.66666667%;
  }
  .rtl-page .col-md-push-7 {
    right: 58.33333333%;
  }
  .rtl-page .col-md-push-6 {
    right: 50%;
  }
  .rtl-page .col-md-push-5 {
    right: 41.66666667%;
  }
  .rtl-page .col-md-push-4 {
    right: 33.33333333%;
  }
  .rtl-page .col-md-push-3 {
    right: 25%;
  }
  .rtl-page .col-md-push-2 {
    right: 16.66666667%;
  }
  .rtl-page .col-md-push-1 {
    right: 8.33333333%;
  }
  .rtl-page .col-md-push-0 {
    right: auto;
  }

}

@media (min-width: 1000px) {
  *[class*="col-lg-"] {
    float: left;
  }
  .rtl-page *[class*="col-lg-"] {
    float: right;
  }
  .colsgrid.reversed > *[class*="col-lg-"] {
    float: right;
  }
  .rtl-page .colsgrid.reversed > *[class*="col-lg-"] {
    float: left;
  }
  .d-flex-lg {
    display: -ms-flexbox !important;
    display: flex !important
  }
  .d-inline-flex-lg {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
  .d-flex-lg > *[class*=col-] {
    float: none;
  }
  .rtl-page .d-flex-lg > *[class*=col-] {
    float: none;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-12 {
    width: 100%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }

  *[class*="col-lg-pull"],
  *[class*="col-lg-push"] {
    position: relative;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }

  .rtl-page *[class*="col-lg-offset-"] {
    margin-left: 0;
  }
  .rtl-page .col-lg-offset-12 {
    margin-right: 100%;
  }
  .rtl-page .col-lg-offset-11 {
    margin-right: 91.66666667%;
  }
  .rtl-page .col-lg-offset-10 {
    margin-right: 83.33333333%;
  }
  .rtl-page .col-lg-offset-9 {
    margin-right: 75%;
  }
  .rtl-page .col-lg-offset-8 {
    margin-right: 66.66666667%;
  }
  .rtl-page .col-lg-offset-7 {
    margin-right: 58.33333333%;
  }
  .rtl-page .col-lg-offset-6 {
    margin-right: 50%;
  }
  .rtl-page .col-lg-offset-5 {
    margin-right: 41.66666667%;
  }
  .rtl-page .col-lg-offset-4 {
    margin-right: 33.33333333%;
  }
  .rtl-page .col-lg-offset-3 {
    margin-right: 25%;
  }
  .rtl-page .col-lg-offset-2 {
    margin-right: 16.66666667%;
  }
  .rtl-page .col-lg-offset-1 {
    margin-right: 8.33333333%;
  }
  .rtl-page .col-lg-offset-0 {
    margin-right: 0;
  }

  .rtl-page *[class*="col-lg-pull-"] {
    right: auto;
  }
  .rtl-page .col-lg-pull-12 {
    left: 100%;
  }
  .rtl-page .col-lg-pull-11 {
    left: 91.66666667%;
  }
  .rtl-page .col-lg-pull-10 {
    left: 83.33333333%;
  }
  .rtl-page .col-lg-pull-9 {
    left: 75%;
  }
  .rtl-page .col-lg-pull-8 {
    left: 66.66666667%;
  }
  .rtl-page .col-lg-pull-7 {
    left: 58.33333333%;
  }
  .rtl-page .col-lg-pull-6 {
    left: 50%;
  }
  .rtl-page .col-lg-pull-5 {
    left: 41.66666667%;
  }
  .rtl-page .col-lg-pull-4 {
    left: 33.33333333%;
  }
  .rtl-page .col-lg-pull-3 {
    left: 25%;
  }
  .rtl-page .col-lg-pull-2 {
    left: 16.66666667%;
  }
  .rtl-page .col-lg-pull-1 {
    left: 8.33333333%;
  }
  .rtl-page .col-lg-pull-0 {
    left: auto;
  }

  .rtl-page *[class*="col-lg-push-"] {
    left: auto;
  }
  .rtl-page .col-lg-push-12 {
    right: 100%;
  }
  .rtl-page .col-lg-push-11 {
    right: 91.66666667%;
  }
  .rtl-page .col-lg-push-10 {
    right: 83.33333333%;
  }
  .rtl-page .col-lg-push-9 {
    right: 75%;
  }
  .rtl-page .col-lg-push-8 {
    right: 66.66666667%;
  }
  .rtl-page .col-lg-push-7 {
    right: 58.33333333%;
  }
  .rtl-page .col-lg-push-6 {
    right: 50%;
  }
  .rtl-page .col-lg-push-5 {
    right: 41.66666667%;
  }
  .rtl-page .col-lg-push-4 {
    right: 33.33333333%;
  }
  .rtl-page .col-lg-push-3 {
    right: 25%;
  }
  .rtl-page .col-lg-push-2 {
    right: 16.66666667%;
  }
  .rtl-page .col-lg-push-1 {
    right: 8.33333333%;
  }
  .rtl-page .col-lg-push-0 {
    right: auto;
  }
}

@media (min-width: 1200px) {
  *[class*="col-xl-"] {
    float: left;
  }
  .rtl-page *[class*="col-xl-"] {
    float: right;
  }
  .colsgrid.reversed > *[class*="col-xl-"] {
    float: right;
  }
  .rtl-page .colsgrid.reversed > *[class*="col-xl-"] {
    float: left;
  }
  .d-flex-xl {
    display: -ms-flexbox !important;
    display: flex !important
  }
  .d-inline-flex-xl {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
  .d-flex-xl > *[class*=col-] {
    float: none;
  }
  .rtl-page .d-flex-xl > *[class*=col-] {
    float: none;
  }

  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-12 {
    width: 100%;
  }

  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }

  *[class*="col-xl-pull"],
  *[class*="col-xl-push"] {
    position: relative;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-pull-11 {
    right: 91.66666667%;
  }
  .col-xl-pull-10 {
    right: 83.33333333%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-8 {
    right: 66.66666667%;
  }
  .col-xl-pull-7 {
    right: 58.33333333%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-5 {
    right: 41.66666667%;
  }
  .col-xl-pull-4 {
    right: 33.33333333%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-2 {
    right: 16.66666667%;
  }
  .col-xl-pull-1 {
    right: 8.33333333%;
  }
  .col-xl-pull-0 {
    right: auto;
  }

  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-push-11 {
    left: 91.66666667%;
  }
  .col-xl-push-10 {
    left: 83.33333333%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-8 {
    left: 66.66666667%;
  }
  .col-xl-push-7 {
    left: 58.33333333%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-5 {
    left: 41.66666667%;
  }
  .col-xl-push-4 {
    left: 33.33333333%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-2 {
    left: 16.66666667%;
  }
  .col-xl-push-1 {
    left: 8.33333333%;
  }
  .col-xl-push-0 {
    left: auto;
  }

  .rtl-page *[class*="col-xl-offset-"] {
    margin-left: 0;
  }
  .rtl-page .col-lg-offset-12 {
    margin-right: 100%;
  }
  .rtl-page .col-xl-offset-11 {
    margin-right: 91.66666667%;
  }
  .rtl-page .col-xl-offset-10 {
    margin-right: 83.33333333%;
  }
  .rtl-page .col-xl-offset-9 {
    margin-right: 75%;
  }
  .rtl-page .col-xl-offset-8 {
    margin-right: 66.66666667%;
  }
  .rtl-page .col-xl-offset-7 {
    margin-right: 58.33333333%;
  }
  .rtl-page .col-xl-offset-6 {
    margin-right: 50%;
  }
  .rtl-page .col-xl-offset-5 {
    margin-right: 41.66666667%;
  }
  .rtl-page .col-xl-offset-4 {
    margin-right: 33.33333333%;
  }
  .rtl-page .col-xl-offset-3 {
    margin-right: 25%;
  }
  .rtl-page .col-xl-offset-2 {
    margin-right: 16.66666667%;
  }
  .rtl-page .col-xl-offset-1 {
    margin-right: 8.33333333%;
  }
  .rtl-page .col-xl-offset-0 {
    margin-right: 0;
  }

  .rtl-page *[class*="col-xl-pull-"] {
    right: auto;
  }
  .rtl-page .col-xl-pull-12 {
    left: 100%;
  }
  .rtl-page .col-xl-pull-11 {
    left: 91.66666667%;
  }
  .rtl-page .col-xl-pull-10 {
    left: 83.33333333%;
  }
  .rtl-page .col-xl-pull-9 {
    left: 75%;
  }
  .rtl-page .col-xl-pull-8 {
    left: 66.66666667%;
  }
  .rtl-page .col-xl-pull-7 {
    left: 58.33333333%;
  }
  .rtl-page .col-xl-pull-6 {
    left: 50%;
  }
  .rtl-page .col-xl-pull-5 {
    left: 41.66666667%;
  }
  .rtl-page .col-xl-pull-4 {
    left: 33.33333333%;
  }
  .rtl-page .col-xl-pull-3 {
    left: 25%;
  }
  .rtl-page .col-xl-pull-2 {
    left: 16.66666667%;
  }
  .rtl-page .col-xl-pull-1 {
    left: 8.33333333%;
  }
  .rtl-page .col-xl-pull-0 {
    left: auto;
  }

  .rtl-page *[class*="col-xl-push-"] {
    left: auto;
  }
  .rtl-page .col-xl-push-12 {
    right: 100%;
  }
  .rtl-page .col-xl-push-11 {
    right: 91.66666667%;
  }
  .rtl-page .col-xl-push-10 {
    right: 83.33333333%;
  }
  .rtl-page .col-xl-push-9 {
    right: 75%;
  }
  .rtl-page .col-xl-push-8 {
    right: 66.66666667%;
  }
  .rtl-page .col-xl-push-7 {
    right: 58.33333333%;
  }
  .rtl-page .col-xl-push-6 {
    right: 50%;
  }
  .rtl-page .col-xl-push-5 {
    right: 41.66666667%;
  }
  .rtl-page .col-xl-push-4 {
    right: 33.33333333%;
  }
  .rtl-page .col-xl-push-3 {
    right: 25%;
  }
  .rtl-page .col-xl-push-2 {
    right: 16.66666667%;
  }
  .rtl-page .col-xl-push-1 {
    right: 8.33333333%;
  }
  .rtl-page .col-xl-push-0 {
    right: auto;
  }
}

@media (max-width: 479px) {
  .cl-b-xs {
    clear: both;
  }
  .hidden-xs {
    display: none !important;
  }
  .visible-xs {
    display: block !important;
  }
  .visible-xs-block {
    display: block !important;
  }
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
  .fl-l-xs {
    float: left;
  }
  .fl-r-xs {
    float: right;
  }
  .ta-left-xs {
    text-align: left;
  }
  .ta-right-xs {
    text-align: right;
  }
  .ta-center-xs {
    text-align: center;
  }
  .rtl-page .fl-r-xs.reversed {
    float: left;
  }
  .rtl-page .fl-l-xs.reversed {
    float: right;
  }
  .rtl-page .ta-left-xs.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-xs.reversed {
    text-align: left;
  }
  .el-block-xs {
    display: block !important;
  }
  .el-inline-xs {
    display: inline !important;
  }
  .el-inline-block-xs {
    display: inline-block !important;
  }
  .el-table-xs {
    display: table !important;
  }
  .el-inline-table-xs {
    display: inline-table !important;
  }
  .el-flex-xs {
    display: flex !important;
  }
  .el-inline-flex-xs {
    display: inline-flex !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .cl-b-sm {
    clear: both;
  }
  .hidden-sm {
    display: none !important;
  }
  .visible-sm {
    display: block !important;
  }
  .visible-sm-block {
    display: block !important;
  }
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
  .fl-l-sm {
    float: left;
  }
  .fl-r-sm {
    float: right;
  }
  .ta-left-sm {
    text-align: left;
  }
  .ta-right-sm {
    text-align: right;
  }
  .ta-center-sm {
    text-align: center;
  }
  .rtl-page .fl-l-sm.reversed {
    float: right;
  }
  .rtl-page .fl-r-sm.reversed {
    float: left;
  }
  .rtl-page .ta-left-sm.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-sm.reversed {
    text-align: left;
  }
  .el-block-sm {
    display: block !important;
  }
  .el-inline-sm {
    display: inline !important;
  }
  .el-inline-block-sm {
    display: inline-block !important;
  }
  .el-table-sm {
    display: table !important;
  }
  .el-inline-table-sm {
    display: inline-table !important;
  }
  .el-flex-sm {
    display: flex !important;
  }
  .el-inline-flex-sm {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .cl-b-md {
    clear: both;
  }
  .hidden-md {
    display: none !important;
  }
  .visible-md {
    display: block !important;
  }
  .visible-md-block {
    display: block !important;
  }
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
  .fl-l-md {
    float: left;
  }
  .fl-r-md {
    float: right;
  }
  .ta-left-md {
    text-align: left;
  }
  .ta-right-md {
    text-align: right;
  }
  .ta-center-md {
    text-align: center;
  }
  .rtl-page .fl-l-md.reversed {
    float: right;
  }
  .rtl-page .fl-r-md.reversed {
    float: left;
  }
  .rtl-page .ta-left-md.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-md.reversed {
    text-align: left;
  }
  .el-block-md {
    display: block !important;
  }
  .el-inline-md {
    display: inline !important;
  }
  .el-inline-block-md {
    display: inline-block !important;
  }
  .el-table-md {
    display: table !important;
  }
  .el-inline-table-md {
    display: inline-table !important;
  }
  .el-flex-md {
    display: flex !important;
  }
  .el-inline-flex-md {
    display: inline-flex !important;
  }
}

@media (min-width: 1000px) and (max-width: 1169px) {
  .cl-b-lg {
    clear: both;
  }
  .hidden-lg {
    display: none !important;
  }
  .visible-lg {
    display: block !important;
  }
  .visible-lg-block {
    display: block !important;
  }
  .visible-lg-inline {
    display: inline !important;
  }
  .visible-lg-inline-block {
    display: inline-block !important;
  }
  .fl-l-lg {
    float: left;
  }
  .fl-r-lg {
    float: right;
  }
  .ta-left-lg {
    text-align: left;
  }
  .ta-right-lg {
    text-align: right;
  }
  .ta-center-lg {
    text-align: center;
  }
  .rtl-page .fl-l-lg.reversed {
    float: right;
  }
  .rtl-page .fl-r-lg.reversed {
    float: left;
  }
  .rtl-page .ta-left-lg.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-lg.reversed {
    text-align: left;
  }
  .el-block-lg {
    display: block !important;
  }
  .el-inline-lg {
    display: inline !important;
  }
  .el-inline-block-lg {
    display: inline-block !important;
  }
  .el-table-lg {
    display: table !important;
  }
  .el-inline-table-lg {
    display: inline-table !important;
  }
  .el-flex-lg {
    display: flex !important;
  }
  .el-inline-flex-lg {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .cl-b-xl {
    clear: both;
  }
  .hidden-xl {
    display: none !important;
  }
  .visible-xl {
    display: block !important;
  }
  .visible-xl-block {
    display: block !important;
  }
  .visible-xl-inline {
    display: inline !important;
  }
  .visible-xl-inline-block {
    display: inline-block !important;
  }
  .fl-l-xl {
    float: left;
  }
  .fl-r-xl {
    float: right;
  }
  .ta-left-xl {
    text-align: left;
  }
  .ta-right-xl {
    text-align: right;
  }
  .ta-center-xl {
    text-align: center;
  }
  .rtl-page .fl-l-xl.reversed {
    float: right;
  }
  .rtl-page .fl-r-xl.reversed {
    float: left;
  }
  .rtl-page .ta-left-xl.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-xl.reversed {
    text-align: left;
  }
  .el-block-xl {
    display: block !important;
  }
  .el-inline-xl {
    display: inline !important;
  }
  .el-inline-block-xl {
    display: inline-block !important;
  }
  .el-table-xl {
    display: table !important;
  }
  .el-inline-table-xl {
    display: inline-table !important;
  }
  .el-flex-xl {
    display: flex !important;
  }
  .el-inline-flex-xl {
    display: inline-flex !important;
  }
}


@media only screen and (max-width: 1200px) {
  .section01 {
    padding: 102px 0 40px;
  }
  .section01 .hp-image {
    max-width: 500px;
    margin: 0 auto;
  }
  .section01 .hp-image > div,
  .rtl-page .section01 .hp-image > div {
    margin: -70px 50px 0;
  }
  .section01 .hp-image:after,
  .rtl-page .section01 .hp-image:after {
    left: -4px;
    right: -4px;
  }

  .section01 .hp-box,
  .rtl-page .section01 .hp-box,
  .section02 .hp-box,
  .rtl-page .section02 .hp-box,
  .section03 .hp-box,
  .rtl-page .section03 .hp-box,
  .section04 .hp-box,
  .rtl-page .section04 .hp-box {
    margin: -4px 0 0 0;
    padding: 75px 35px 55px;
  }

}

@media only screen and (max-width: 1000px) {
  .show-less-1000 {
    display: block
  }
  .mt0-md {
    margin-top: 0;
  }
  .mb0-md {
    margin-bottom: 0;
  }
  .ml0-md {
    margin-left: 0;
  }
  .ml0-md.reversed {
    margin-right: 0;
  }
  .mr0-md {
    margin-right: 0;
  }
  .mr0-md.reversed {
    margin-left: 0;
  }
  .btn-block-md {
    display: block;
    min-width: 1px !important;
  }


  .section02 .layer {
    flex-direction: column-reverse;
  }
  .section02 .hp-image:after {
    left: -20px;
    right: -20px;
    top: 20px;
    bottom: 0;
  }
  .section02 .hp-image,
  .rtl-page .section02 .hp-image {
    margin: -4px auto 0;
    max-width: 500px;
  }
  .section02 .hp-image > div,
  .rtl-page .section02 .hp-image > div {
    margin: 0;
  }
  .section02 .hp-box,
  .rtl-page .section02 .hp-box {
    margin: -23px 0 0;
    z-index: 2;
  }

  .section03 .layer {
    flex-direction: column;
  }
  .section03 .hp-image,
  .rtl-page .section03 .hp-image {
    margin: -4px auto 0;
    max-width: 500px;
  }
  .section03 .hp-image:after {
    left: -20px;
    right: -20px;
    top: 20px;
    bottom: 0;
  }
  .section03 .hp-image > div,
  .rtl-page .section03 .hp-image > div {
    margin: 0;
  }
  .section03 .hp-box,
  .rtl-page .section03 .hp-box {
    margin: -50px 0 0;
    z-index: 2;
  }
  .section03 .hp-box.decor:before {
    bottom: 20px;
  }

  .section04 .layer {
    flex-direction: column-reverse;
  }
  .section04 .hp-image:after {
    left: -20px;
    right: -20px;
    top: 20px;
    bottom: 0;
  }
  .section04 .hp-image,
  .rtl-page .section04 .hp-image {
    margin: -4px auto 0;
    max-width: 500px;
  }
  .section04 .hp-image > div,
  .rtl-page .section04 .hp-image > div {
    margin: 0;
  }
  .section04 .hp-box,
  .rtl-page .section04 .hp-box {
    margin: -24px 0 0;
    z-index: 2;
  }


  .section05 .text {
    padding: 0 0 50px;
  }
  .section05 .items,
  .rtl-page .section05 .items {
    padding: 0;
  }
  .section05 .items {
    font: 600 15px/22px 'Montserrat', sans-serif;
  }
  .section05 .items *[class*=col-]:nth-child(2),
  .section05 .items *[class*=col-]:nth-child(4) {
    top: 0;
  }

  .section07 .items {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .section08 .images {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .show-less-767 {
    display: block
  }
  .mt0-sm {
    margin-top: 0;
  }
  .mb0-sm {
    margin-bottom: 0;
  }
  .ml0-sm {
    margin-left: 0;
  }
  .ml0-sm.reversed {
    margin-right: 0;
  }
  .mr0-sm {
    margin-right: 0;
  }
  .mr0-sm.reversed {
    margin-left: 0;
  }

  .mt10-sm {
    margin-top: 10px;
  }
  .mb10-sm {
    margin-bottom: 10px;
  }
  .mt15-sm {
    margin-top: 15px;
  }
  .mb15-sm {
    margin-bottom: 15px;
  }
  .mt20-sm {
    margin-top: 20px;
  }
  .mb20-sm {
    margin-bottom: 20px;
  }

  .btn-block-sm {
    display: block;
    min-width: 1px !important;
  }

  body {
    font-size: 15px;
    line-height: 22px;
  }

  h1, .h1 {
    font: 600 24px/29px 'Montserrat', sans-serif;
    margin-bottom: 28px;
    letter-spacing: 0;
  }
  h2, .h2 {
    font: 600 18px/24px 'Montserrat', sans-serif;
    margin-bottom: 10px;
  }
  h3, .h3 {
    font: 600 18px/24px 'Montserrat', sans-serif;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 22px;
  }


  .decor:before {
    width: 170px;
    height: 199px;
  }

  .section02 .hp-box.decor:before {
    bottom: -100px;
    left: -13px;
  }
  .image-position01,
  .rtl-page .image-position01 {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .list li {
    margin: 0 0 7px;
  }
  .list li ul {
    margin-top: 7px;
  }
  .more {
    font-size: 14px;
  }


  .boxes.decor:before {
    left: -15px;
    bottom: 25px;
  }
  .rtl-page .boxes.decor:before {
    left: auto;
    right: -15px;
  }
  .boxes .box {
    padding: 19px;
  }
  .boxes .box .box-content {
    padding: 20px 0 50px;
  }
  .boxes .box .image {
    padding: 0;
    margin-top: 20px;
  }
  .boxes .box .image img {
    position: static;
    width: auto;
    height: 132px;
  }

  .box-blockquote {
    padding: 19px;
  }
  .box-blockquote .box-content {
    position: relative;
  }
  .box-blockquote .box-content:before {
    left: 0;
    top: 0;
  }
  .box-blockquote .box-content,
  .box-blockquote.flex-row .box-content,
  .rtl-page .box-blockquote .box-content,
  .rtl-page .box-blockquote.flex-row .box-content {
    padding: 33px 0 0;
  }
  .box-blockquote .image {
    margin: 0 0 20px;
  }
  .box-blockquote .image img {
    height: 176px;
  }
  .box-blockquote q {
    margin-bottom: 10px;
  }
  .box-blockquote cite {
    font-size: 15px;
  }
  .box-blockquote.flex-row .box-content:before {
    left: auto;
    right: 0;
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .rtl-page .box-blockquote .box-content:before {
    left: auto;
    right: 0;
  }
  .rtl-page .box-blockquote.flex-row .box-content:before {
    right: auto;
    left: 0;
  }


  .section01 .hp-box,
  .rtl-page .section01 .hp-box,
  .section02 .hp-box,
  .rtl-page .section02 .hp-box,
  .section03 .hp-box,
  .rtl-page .section03 .hp-box,
  .section04 .hp-box,
  .rtl-page .section04 .hp-box {
    padding: 30px 15px;
  }

  .section01 {
    padding: 92px 0 49px;
  }

  .section01 .hp-image:after,
  .rtl-page .section01 .hp-image:after {
    left: 0;
    right: 0;
  }
  .section01 .hp-image.decor:before {
    left: auto;
    right: -76px;
    bottom: auto;
    top: -45px;
  }
  .rtl-page .section01 .hp-image.decor:before {
    right: auto;
    left: -76px;
  }


  .section01 h1,
  .section01 .h1 {
    margin-bottom: 16px;
  }
  .section01 p {
    margin: 0 0 5px
  }
  .section01 .hp-box.decor:before {
    content: none;
  }

  .section02 {
    padding: 50px 0;
  }

  .section03 {
    padding: 50px 0;
  }

  .section05 {
    padding: 40px 0 30px;
  }
  .section05 .text {
    padding: 0;
  }
  .section05 .items,
  .rtl-page .section05 .items {
    padding: 0 15px;
  }
  .section05 .items *[class*=col-] {
    margin: 0 0 20px;
  }

  .section05 .item.box {
    min-height: 164px;
  }
  .section05 .item .box-content {
    padding: 14px 30px;
  }
  .section05 .item *[class*=icon-] {
    width: 80px;
    height: 80px;
    font-size: 30px;
    line-height: 72px;
    margin: 0 0 10px;
  }
  .section05 h1,
  .section05 .h1 {
    margin-bottom: 16px;
    font-size: 24px;
  }
  .section05 p strong {
    font-size: 19px;
    line-height: 26px;
  }

  .section06,
  .section07,
  .section08 {
    padding: 50px 0;
  }

  .section08 {
    text-align: center;
  }
  .section08 .h1 {
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .section08 .items {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }


  .hp-icon {
    width: 60px;
    height: 60px;
    font-size: 27px;
    line-height: 27px;
  }

  .payment {
    padding: 5px 0 20px;
  }
  .popup-content {
    padding: 30px 15px
  }
  .popup-app h2 {
    font: 600 24px/32px 'Montserrat', sans-serif;
    margin-bottom: 20px;
  }

  .acc-types-custom.table {
    box-shadow: none;
    border-radius: 0;
    margin-right: 0;
    margin-left: 0;
    overflow: visible;
  }
  .acc-table-wrapper {
    display: block;

  }
}

.layer {
  position: relative;
  z-index: 999;
}

.bmg-list-paginator .bmg-list-paginator__bttn {
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .bg[class*=section]:before {
    background: url("#{$site-domain}/Media/Image/bg-w2-xs.png") repeat-y 50% 0;
    z-index: 999;
  }

  .section06 .decor01:before,
  .section06 .decor01:after,
    /*.section06 .decor02:before,*/
  .section06 .decor02:after {
    display: none;
  }

  .section02 {
    padding: 0 0 69px;
  }
  .section03,
  .section04 {
    padding: 0 0 50px;
  }

  .section01 .hp-image,
  .section02 .hp-image,
  .section03 .hp-image,
  .section04 .hp-image {
    max-width: unset;
    margin: -4px -19px 0;
  }
  .section04 .hp-image {
    margin-inline-start: calc(-19px - 4%);
  }

  .section07 .heading p {
    margin-bottom: 30px;
    font-size: 20px;
  }
  .section07 .item {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 19px 14px;
    gap: 16px;
    position: relative;
    z-index: 999;
  }
  .section07 .item h3 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #DBDBDB;
  }
}

@media only screen and (max-width: 479px) {
  .section07 .heading .h1 {
    margin-bottom: 16px;
  }
  .show-less-479 {
    display: block
  }
  .mt0-xs {
    margin-top: 0;
  }
  .mb0-xs {
    margin-bottom: 0;
  }
  .ml0-xs {
    margin-left: 0;
  }
  .ml0-xs.reversed {
    margin-right: 0;
  }
  .mr0-xs {
    margin-right: 0;
  }
  .mr0-xs.reversed {
    margin-left: 0;
  }
  .btn-block-xs {
    display: block;
    min-width: 1px !important;
  }

  .markets-news .item .image img {
    width: 100%;
  }
  .boxes .box .image {
    margin-top: 0;
    padding: 0 0 50.4%;
  }
  .boxes .box .image img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .box-blockquote .image img {
    width: 100%;
  }

  .section01 .hp-image {
    margin: 0;
    padding-inline-start: 15px;
    padding-inline-end: 75px;
  }
  .section01 .hp-image:after {
    left: 24px;
    right: 24px;
    top: -4px;
  }
  .section01 .hp-box {
    z-index: 1;
    margin: -28px 0 0;
    padding: 30px 15px;
  }
  .section01 .hp-image > div,
  .rtl-page .section01 .hp-image > div {
    margin: -50px 2px 0;
  }
  .section05 .items,
  .rtl-page .section05 .items {
    padding: 0 50px;
  }
  .section06 .hp-image,
  .rtl-page .section06 .hp-image {
    margin: 0 30px -4px;
  }
  .section06 .hp-image > div,
  .rtl-page .section06 .hp-image > div {
    margin: -45px 0 20px;
  }
}

