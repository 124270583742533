::-webkit-scrollbar {
  height: 1rem;
  width: 1rem
}

::-webkit-scrollbar-track {
  background-color: var(--primary-bg)
}

::-webkit-scrollbar-thumb {
  height: auto;
  background: var(--accent-color);
  width: 1rem
}

::selection {
  color: var(--white-color);
  background-color: var(--accent-color);
}


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, img, ins, kbd, q, s, samp, small, strike, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  scrollbar-color: var(--accent-color) var(--primary-bg);
  scrollbar-width: thin;
  font-size: 10px;
}

.with__sidebar__page, .error__page, .layout__page {
  .main__container {
    border-top: 1px solid #2C3944;
    background: #0F1821;
    color: #fff;
    h1, h2, h3, h4, h5 {
      color: #fff;
    }
  }
}
.fullLayout__page{
  .main__container{
    background: #14222F url("#{$site-domain}/Media/Image/bg-page-full.jpg") no-repeat 50% 50% / cover;
    color: #fff;
    h1, h2, h3, h4, h5 {
      color: #fff;
    }
  }
}

.error__page .page-notfound-content {
  color: #DBDBDB;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  p {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}


article, aside, details, figcaption, figure,
footer, header, main, hgroup, menu, nav, section {
  display: block;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

sub, sup {
  position: relative;
  font-size: 65%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.font-size-16 {
  font-size: 1.6rem;
  line-height: 2.6rem;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
}

#map_canvas img, .google-maps img {
  max-width: none;
}

video, embed, object, iframe {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  min-width: 320px;
  font-size: 16px;
  line-height: 26px;
  color: #4f4f4f;
  font-family: var(--main-font-family);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

* {
  font-family: var(--main-font-family) !important;
}

.with__bannerSidebar__page {
  .sidebar__holder .title {
    display: none;
  }
}

img {
  opacity: 1;
  transition: opacity 0.3s;
  display: inline-block;
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

a {
  color: var(--accent-color);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.tdn {
  text-decoration: none;
}

.tdu {
  text-decoration: underline;
}

.tdn:hover {
  text-decoration: underline;
}

.tdu:hover {
  text-decoration: none;
}

#wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.rtl-page #wrapper {
  direction: rtl;
}

.wrapper__container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.main-holder {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 1.5rem;

  .main-holder {
    padding-left: 0;
    padding-right: 0;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.main__container {
  flex: 1;
  padding: 9rem 0 5rem;
  position: relative;
  min-height: 510px;
}


strong {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: var(--primary-font-family);
  color: var(--primary-color);
}

h1, .h1 {
  font-size: 3.8rem;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 4.5rem;
}

h2, .h2 {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 110%;
  margin-bottom: 2rem;
}

h3, .h3 {
  font-size: 2rem;
  line-height: 110%;
  font-weight: 700;
  margin-bottom: 2rem;
}

p {
  margin-bottom: 2rem;
}

.color01 {
  color: #fff
}

.color02 {
  color: #000
}

.color03 {
  color: var(--accent-color)
}

.color04 {
  color: var(--secondary-color)
}

.fw100 {
  font-weight: 100;
}

.fw200 {
  font-weight: 200;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.content-holder {
  display: flex;
  flex-direction: row-reverse;
}

.content {
  flex: 1;
  min-height: 0.1rem;
}

#sidebar {
  width: 27rem;
  margin-inline-end: 3rem;
}

.content-margin {
  margin-bottom: 4rem;
}

.color01 {
  color: #fff
}

.color02 {
  color: #000
}

.color03 {
  color: var(--accent-color)
}

.color04 {
  color: var(--secondary-color)
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.chold01 {
  max-width: 80rem;
}

.chold02 {
  max-width: 77rem;
}

.chold03 {
  max-width: 37rem;
}

.list {
  margin: 0 0 2rem;
  padding: 0;
  list-style: none;
}

.list li {
  position: relative;
  margin: 0 0 .7rem;
  padding: 0 0 0 1.6rem;
}

.list li:before {
  content: "";
  position: absolute;
  left: 0;
  background: var(--accent-color);
  top: 1rem;
  width: 6px;
  height: 6px;
}

.list li ul {
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
}

.rtl-page {
  .list li {
    padding: 0 1.6rem 0 0;

    &:before {
      left: auto;
      right: 0;
    }
  }
}

.font-family01 {
  font-family: 'Noto Sans', serif;
}

.button {
  display: inline-flex;
  position: relative;
  padding: 1.5rem;
  min-width: 23rem;
  font-family: var(--main-font-family);
  color: #14222F;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: var(--button-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 3px;

  &:hover,
  &.active {
    background: #0F1821;
    text-decoration: none;
    color: var(--white-color);
  }
}

.button:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: .2rem solid transparent;
}

.button span {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.button2 {
  background: var(--button2-bg);
}

.button2:hover {
  background: var(--button2-bg-hover);
}

.button3 {
  background: var(--button3-bg);
  color: var(--primary-color);
}

.button3:hover,
.button3.active {
  background: var(--button3-bg-hover);
  color: var(--white-color);
}

.button.bordered {
  color: var(--accent-color);
  background: transparent;
}

.button.bordered:before {
  border-color: var(--button-bg);
}

.button.bordered:hover,
.button.bordered.active {
  background: var(--button-bg);
  color: var(--white-color);
}

.button2.bordered {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--secondary-color);
}

.button2.bordered:before {
  border-color: var(--secondary-color)
}

.button2.bordered:hover,
.button2.bordered.active {
  background: var(--button2-bg);
  color: var(--white-color);
}

.button2.bordered:hover:before,
.button2.bordered.active:before {
  border-color: var(--button2-bg);
}

.button3.bordered {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--white-color);
}

.button3.bordered:before {
  border-color: var(--button3-bg)
}

.button3.bordered:hover,
.button3.bordered.active {
  background: var(--button3-bg-hover);
}

.button3.bordered:hover:before,
.button3.bordered.active:before {
  border-color: var(--button3-bg-hover);
}

.btn-md {
  min-width: 11.3rem;
  padding: 1.35rem 1rem;
}

.btn-xs {
  line-height: 1.6rem;
  min-width: 11rem;
  padding: 1.2rem 1rem;
}

.btn-lg {
  font-size: 1.6rem;
  padding: 1.7rem 3rem;
  min-width: 21.6rem;
}

.btn-more {
  padding: 1.35rem 3rem;
  min-width: 13rem;
}

.btn-sz01 {
  min-width: 19rem;
}

.btn-sz02 {
  min-width: 16.4rem;
}

.btn-sz03 {
  min-width: 13.3rem;
}

.btn-sz04 {
  min-width: 9rem;
}

.btn-sz05 {
  min-width: 12.5rem;
}

.btn-block {
  display: block;
  min-width: .1rem;
}

.centered-buttons {
  text-align: center;
  margin: 0 -1.5rem;
}

.centered-buttons .button {
  margin: 0 1.5rem 1.5rem;
}

.line {
  height: .1rem;
  display: block;
  border-top-width: .1rem;
  border-color: rgba(255, 255, 255, 0.15);
  border-style: solid;
  overflow: hidden;
}

.line.dashed {
  border-style: dashed;
}

.line.dotted {
  border-style: dotted;
}

.social {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.more-link {
  margin: 0 auto;

  a {
    text-decoration: none;
  }
}


@media only screen and (max-width: 1200px) {
  .home__page .wrapper__container {
    padding: 0;
  }
  .content-holder {
    display: block;
  }
  #sidebar {
    display: none;
  }
  .btn-block-lg {
    display: block;
    min-width: .1rem !important;
  }
}

@media only screen and (max-width: 1000px) {
  .btn-block-md {
    display: block;
    min-width: .1rem !important;
  }
}

@media only screen and (max-width: 767px) {


  h1, .h1 {
    font-size: 2.8rem;
    margin-bottom: 2.5rem;
  }
  h2, .h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  h3, .h3 {
    font-size: 1.8rem;
  }

  .main__container {
    padding: 8rem 0 4rem;
  }

  .btn-block-sm {
    display: block;
    min-width: .1rem !important;
  }
}

@media only screen and (max-width: 479px) {
  .btn-block-xs {
    display: block;
    min-width: .1rem !important;
  }
  .btn-xs {
    padding: 0.7rem 0.5rem;
    font-size: 1.4rem;
  }
  h4, .h4 {
    margin-bottom: 2rem;
  }
}


.show-less-575,
.show-less-767,
.show-less-991,
.show-less-1200,
.show-less-1450 {
  display: none;
}

@media only screen and (max-width: 1450px) {
  .show-less-1450 {
    display: block
  }
}

@media only screen and (max-width: 1200px) {
  .show-less-1200 {
    display: block
  }
}

@media only screen and (max-width: 991px) {
  .show-less-991 {
    display: block
  }
}

@media only screen and (max-width: 767px) {
  .show-less-767 {
    display: block
  }
}

@media only screen and (max-width: 575px) {
  .show-less-575 {
    display: block
  }
}

@media (min-width: 1200px) {
  *[class*="col-xl-"] {
    float: left;
  }
  .rtl-page *[class*="col-xl-"] {
    float: right;
  }
  .colsgrid.reversed > *[class*="col-xl-"] {
    float: right;
  }
  .rtl-page .colsgrid.reversed > *[class*="col-xl-"] {
    float: left;
  }
  .d-flex-xl {
    display: -ms-flexbox !important;
    display: flex !important
  }
  .d-inline-flex-xl {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
  .d-flex-xl > *[class*=col-] {
    float: none;
  }
  .rtl-page .d-flex-xl > *[class*=col-] {
    float: none;
  }

  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-12 {
    width: 100%;
  }

  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }

  *[class*="col-xl-pull"],
  *[class*="col-xl-push"] {
    position: relative;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-pull-11 {
    right: 91.66666667%;
  }
  .col-xl-pull-10 {
    right: 83.33333333%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-8 {
    right: 66.66666667%;
  }
  .col-xl-pull-7 {
    right: 58.33333333%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-5 {
    right: 41.66666667%;
  }
  .col-xl-pull-4 {
    right: 33.33333333%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-2 {
    right: 16.66666667%;
  }
  .col-xl-pull-1 {
    right: 8.33333333%;
  }
  .col-xl-pull-0 {
    right: auto;
  }

  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-push-11 {
    left: 91.66666667%;
  }
  .col-xl-push-10 {
    left: 83.33333333%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-8 {
    left: 66.66666667%;
  }
  .col-xl-push-7 {
    left: 58.33333333%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-5 {
    left: 41.66666667%;
  }
  .col-xl-push-4 {
    left: 33.33333333%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-2 {
    left: 16.66666667%;
  }
  .col-xl-push-1 {
    left: 8.33333333%;
  }
  .col-xl-push-0 {
    left: auto;
  }

  .rtl-page *[class*="col-xl-offset-"] {
    margin-left: 0;
  }
  .rtl-page .col-lg-offset-12 {
    margin-right: 100%;
  }
  .rtl-page .col-xl-offset-11 {
    margin-right: 91.66666667%;
  }
  .rtl-page .col-xl-offset-10 {
    margin-right: 83.33333333%;
  }
  .rtl-page .col-xl-offset-9 {
    margin-right: 75%;
  }
  .rtl-page .col-xl-offset-8 {
    margin-right: 66.66666667%;
  }
  .rtl-page .col-xl-offset-7 {
    margin-right: 58.33333333%;
  }
  .rtl-page .col-xl-offset-6 {
    margin-right: 50%;
  }
  .rtl-page .col-xl-offset-5 {
    margin-right: 41.66666667%;
  }
  .rtl-page .col-xl-offset-4 {
    margin-right: 33.33333333%;
  }
  .rtl-page .col-xl-offset-3 {
    margin-right: 25%;
  }
  .rtl-page .col-xl-offset-2 {
    margin-right: 16.66666667%;
  }
  .rtl-page .col-xl-offset-1 {
    margin-right: 8.33333333%;
  }
  .rtl-page .col-xl-offset-0 {
    margin-right: 0;
  }

  .rtl-page *[class*="col-xl-pull-"] {
    right: auto;
  }
  .rtl-page .col-xl-pull-12 {
    left: 100%;
  }
  .rtl-page .col-xl-pull-11 {
    left: 91.66666667%;
  }
  .rtl-page .col-xl-pull-10 {
    left: 83.33333333%;
  }
  .rtl-page .col-xl-pull-9 {
    left: 75%;
  }
  .rtl-page .col-xl-pull-8 {
    left: 66.66666667%;
  }
  .rtl-page .col-xl-pull-7 {
    left: 58.33333333%;
  }
  .rtl-page .col-xl-pull-6 {
    left: 50%;
  }
  .rtl-page .col-xl-pull-5 {
    left: 41.66666667%;
  }
  .rtl-page .col-xl-pull-4 {
    left: 33.33333333%;
  }
  .rtl-page .col-xl-pull-3 {
    left: 25%;
  }
  .rtl-page .col-xl-pull-2 {
    left: 16.66666667%;
  }
  .rtl-page .col-xl-pull-1 {
    left: 8.33333333%;
  }
  .rtl-page .col-xl-pull-0 {
    left: auto;
  }

  .rtl-page *[class*="col-xl-push-"] {
    left: auto;
  }
  .rtl-page .col-xl-push-12 {
    right: 100%;
  }
  .rtl-page .col-xl-push-11 {
    right: 91.66666667%;
  }
  .rtl-page .col-xl-push-10 {
    right: 83.33333333%;
  }
  .rtl-page .col-xl-push-9 {
    right: 75%;
  }
  .rtl-page .col-xl-push-8 {
    right: 66.66666667%;
  }
  .rtl-page .col-xl-push-7 {
    right: 58.33333333%;
  }
  .rtl-page .col-xl-push-6 {
    right: 50%;
  }
  .rtl-page .col-xl-push-5 {
    right: 41.66666667%;
  }
  .rtl-page .col-xl-push-4 {
    right: 33.33333333%;
  }
  .rtl-page .col-xl-push-3 {
    right: 25%;
  }
  .rtl-page .col-xl-push-2 {
    right: 16.66666667%;
  }
  .rtl-page .col-xl-push-1 {
    right: 8.33333333%;
  }
  .rtl-page .col-xl-push-0 {
    right: auto;
  }
}

@media (max-width: 479px) {
  .cl-b-xs {
    clear: both;
  }
  .hidden-xs {
    display: none !important;
  }
  .visible-xs {
    display: block !important;
  }
  .visible-xs-block {
    display: block !important;
  }
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
  .fl-l-xs {
    float: left;
  }
  .fl-r-xs {
    float: right;
  }
  .ta-left-xs {
    text-align: left;
  }
  .ta-right-xs {
    text-align: right;
  }
  .ta-center-xs {
    text-align: center;
  }
  .rtl-page .fl-r-xs.reversed {
    float: left;
  }
  .rtl-page .fl-l-xs.reversed {
    float: right;
  }
  .rtl-page .ta-left-xs.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-xs.reversed {
    text-align: left;
  }
  .el-block-xs {
    display: block !important;
  }
  .el-inline-xs {
    display: inline !important;
  }
  .el-inline-block-xs {
    display: inline-block !important;
  }
  .el-table-xs {
    display: table !important;
  }
  .el-inline-table-xs {
    display: inline-table !important;
  }
  .el-flex-xs {
    display: flex !important;
  }
  .el-inline-flex-xs {
    display: inline-flex !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .cl-b-sm {
    clear: both;
  }
  .hidden-sm {
    display: none !important;
  }
  .visible-sm {
    display: block !important;
  }
  .visible-sm-block {
    display: block !important;
  }
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
  .fl-l-sm {
    float: left;
  }
  .fl-r-sm {
    float: right;
  }
  .ta-left-sm {
    text-align: left;
  }
  .ta-right-sm {
    text-align: right;
  }
  .ta-center-sm {
    text-align: center;
  }
  .rtl-page .fl-l-sm.reversed {
    float: right;
  }
  .rtl-page .fl-r-sm.reversed {
    float: left;
  }
  .rtl-page .ta-left-sm.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-sm.reversed {
    text-align: left;
  }
  .el-block-sm {
    display: block !important;
  }
  .el-inline-sm {
    display: inline !important;
  }
  .el-inline-block-sm {
    display: inline-block !important;
  }
  .el-table-sm {
    display: table !important;
  }
  .el-inline-table-sm {
    display: inline-table !important;
  }
  .el-flex-sm {
    display: flex !important;
  }
  .el-inline-flex-sm {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .cl-b-md {
    clear: both;
  }
  .hidden-md {
    display: none !important;
  }
  .visible-md {
    display: block !important;
  }
  .visible-md-block {
    display: block !important;
  }
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
  .fl-l-md {
    float: left;
  }
  .fl-r-md {
    float: right;
  }
  .ta-left-md {
    text-align: left;
  }
  .ta-right-md {
    text-align: right;
  }
  .ta-center-md {
    text-align: center;
  }
  .rtl-page .fl-l-md.reversed {
    float: right;
  }
  .rtl-page .fl-r-md.reversed {
    float: left;
  }
  .rtl-page .ta-left-md.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-md.reversed {
    text-align: left;
  }
  .el-block-md {
    display: block !important;
  }
  .el-inline-md {
    display: inline !important;
  }
  .el-inline-block-md {
    display: inline-block !important;
  }
  .el-table-md {
    display: table !important;
  }
  .el-inline-table-md {
    display: inline-table !important;
  }
  .el-flex-md {
    display: flex !important;
  }
  .el-inline-flex-md {
    display: inline-flex !important;
  }
}

@media (min-width: 1000px) and (max-width: 1169px) {
  .cl-b-lg {
    clear: both;
  }
  .hidden-lg {
    display: none !important;
  }
  .visible-lg {
    display: block !important;
  }
  .visible-lg-block {
    display: block !important;
  }
  .visible-lg-inline {
    display: inline !important;
  }
  .visible-lg-inline-block {
    display: inline-block !important;
  }
  .fl-l-lg {
    float: left;
  }
  .fl-r-lg {
    float: right;
  }
  .ta-left-lg {
    text-align: left;
  }
  .ta-right-lg {
    text-align: right;
  }
  .ta-center-lg {
    text-align: center;
  }
  .rtl-page .fl-l-lg.reversed {
    float: right;
  }
  .rtl-page .fl-r-lg.reversed {
    float: left;
  }
  .rtl-page .ta-left-lg.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-lg.reversed {
    text-align: left;
  }
  .el-block-lg {
    display: block !important;
  }
  .el-inline-lg {
    display: inline !important;
  }
  .el-inline-block-lg {
    display: inline-block !important;
  }
  .el-table-lg {
    display: table !important;
  }
  .el-inline-table-lg {
    display: inline-table !important;
  }
  .el-flex-lg {
    display: flex !important;
  }
  .el-inline-flex-lg {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .cl-b-xl {
    clear: both;
  }
  .hidden-xl {
    display: none !important;
  }
  .visible-xl {
    display: block !important;
  }
  .visible-xl-block {
    display: block !important;
  }
  .visible-xl-inline {
    display: inline !important;
  }
  .visible-xl-inline-block {
    display: inline-block !important;
  }
  .fl-l-xl {
    float: left;
  }
  .fl-r-xl {
    float: right;
  }
  .ta-left-xl {
    text-align: left;
  }
  .ta-right-xl {
    text-align: right;
  }
  .ta-center-xl {
    text-align: center;
  }
  .rtl-page .fl-l-xl.reversed {
    float: right;
  }
  .rtl-page .fl-r-xl.reversed {
    float: left;
  }
  .rtl-page .ta-left-xl.reversed {
    text-align: right;
  }
  .rtl-page .ta-right-xl.reversed {
    text-align: left;
  }
  .el-block-xl {
    display: block !important;
  }
  .el-inline-xl {
    display: inline !important;
  }
  .el-inline-block-xl {
    display: inline-block !important;
  }
  .el-table-xl {
    display: table !important;
  }
  .el-inline-table-xl {
    display: inline-table !important;
  }
  .el-flex-xl {
    display: flex !important;
  }
  .el-inline-flex-xl {
    display: inline-flex !important;
  }
}
